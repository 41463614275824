import AddIcon from "@mui/icons-material/Add";
import {
  IconButton,
  Slider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useDebouncedValue } from "../../hooks/useDebouncedValue";
import { getStackPolygons } from "../../hooks/utils";
import { calculateUtilization } from "../../screens/Hangar/HangarInfoCard";
import { useDateRange } from "../../screens/Hangar/HangarPresenter";
import { Hangar, Location, Ramp } from "../../types";

type Props = {
  location: Location<Hangar | Ramp>;
};

export const DateTimeRangeSelector: React.FC<Props> = ({ location }) => {
  const {
    activeDate,
    minDate,
    maxDate,
    setActiveDate,
    setMinDate,
    setMaxDate,
  } = useDateRange();
  const { activeFBO } = useActiveFBOs();
  // Calculate the difference in milliseconds
  const dateRangeInMillis = maxDate.getTime() - minDate.getTime();
  const activeDateInMillisRelativeToMinDate =
    minDate.getTime() - activeDate.getTime();

  const entityPolygons = useDebouncedValue(
    () => getStackPolygons(location.stack, location.width, 1),
    50,
    [location.stack]
  );

  const utilization = useDebouncedValue<number>(
    () => {
      return (
        calculateUtilization(
          location as Hangar,
          entityPolygons,
          true,
          activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
        ) / 100
      );
    },
    100,
    [
      // hangar,
      entityPolygons,
      activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false,
    ]
  );

  const handleSliderChange = (event: Event, newValue: number | number[]) => {
    if (Array.isArray(newValue)) return;

    const dateInMillis = minDate.getTime() - newValue;
    const roundedDateInMillis =
      Math.round(dateInMillis / (15 * 60 * 1000)) * (15 * 60 * 1000);
    const date = new Date(roundedDateInMillis);
    setActiveDate(date);
  };

  if (1 === 1) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={0}
        height="100%"
        mt={0.5}
        ml={6}
      >
        <TextField
          fullWidth
          type="date"
          onChange={(evt) => {
            const newMinDate = new Date(evt.target.value);
            if (newMinDate > maxDate) {
              newMinDate.setDate(maxDate.getDate() - 2);
            }
            setMinDate(newMinDate);
          }}
          value={minDate.toISOString().split("T")[0]}
          size="small"
          sx={{ width: 150 }}
        />
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              const newActiveDate = Math.max(
                +minDate,
                +activeDate - 15 * 60 * 1000
              );
              setActiveDate(new Date(newActiveDate));
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              const newActiveDate = Math.max(
                +minDate,
                +activeDate - 60 * 60 * 1000
              );
              setActiveDate(new Date(newActiveDate));
            }}
          >
            <AddIcon />
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              const newActiveDate = Math.max(
                +minDate,
                +activeDate - 24 * 60 * 60 * 1000
              );
              setActiveDate(new Date(newActiveDate));
            }}
          >
            <AddIcon />
            <AddIcon />
            <AddIcon />
          </IconButton>
        </Stack>
        <Slider
          orientation="vertical"
          track={false}
          value={activeDateInMillisRelativeToMinDate}
          onChange={handleSliderChange}
          min={-dateRangeInMillis}
          max={0}
          valueLabelDisplay="on"
          step={
            // 15 minutes
            15 * 60 * 1000
          }
          valueLabelFormat={(value) => {
            const dateInMillis = minDate.getTime() - value;
            const roundedDateInMillis =
              Math.round(dateInMillis / (15 * 60 * 1000)) * (15 * 60 * 1000);
            const dt = new Date(roundedDateInMillis)
              .toLocaleString("en-GB", {
                month: "short",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false,
                timeZone: "UTC", // Specify the desired time zone
              })
              .replace(",", "");

            const utilizationColorThresholds = [
              { breakpoint: 0.5, color: "red" },
              { breakpoint: 0.75, color: "orange" },
              { breakpoint: 1.0, color: "green" },
            ];
            let color = "grey";
            for (const threshold of utilizationColorThresholds) {
              if (utilization < threshold.breakpoint) {
                break;
              }
              color = threshold.color;
            }

            return (
              <Stack direction="column" alignItems="center">
                <Typography>{dt}</Typography>
                <Typography sx={{ color }}>
                  {(utilization * 100).toFixed(0)}%
                </Typography>
              </Stack>
            );
          }}
          sx={{
            height: "75%",
            "& .MuiSlider-thumb": {
              backgroundColor: "#1976d2", // Custom color for the thumb
              width: 24,
              height: 12,
              borderRadius: "50%",
              "&:before": {
                content: '""',
                width: 24,
                height: 12,
                borderRadius: "50%",
                backgroundColor: "transparent", // Ensure no border
              },
            },
            "& .MuiSlider-valueLabel": {
              backgroundColor: "transparent", // Set background to transparent
              color: "primary.main", // Custom color for the value label
              borderRadius: "4px", // Optional: Round corners of the value label
              boxShadow: "none", // Optional: Remove box shadow
              padding: "4px 8px", // Optional: Add padding for better appearance
            },
          }}
        />
        <Stack direction="row" spacing={1}>
          <IconButton
            onClick={() => {
              const newActiveDate = Math.min(
                +maxDate,
                +activeDate + 15 * 60 * 1000
              );
              setActiveDate(new Date(newActiveDate));
            }}
          >
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              const newActiveDate = Math.min(
                +maxDate,
                +activeDate + 60 * 60 * 1000
              );
              setActiveDate(new Date(newActiveDate));
            }}
          >
            <AddIcon />
            <AddIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              const newActiveDate = Math.min(
                +maxDate,
                +activeDate + 24 * 60 * 60 * 1000
              );
              setActiveDate(new Date(newActiveDate));
            }}
          >
            <AddIcon />
            <AddIcon />
            <AddIcon />
          </IconButton>
        </Stack>
        <TextField
          fullWidth
          type="date"
          onChange={(evt) => {
            const newMaxDate = new Date(evt.target.value);
            if (newMaxDate < minDate) {
              newMaxDate.setDate(maxDate.getDate() + 2);
            }
            setMaxDate(newMaxDate);
          }}
          value={maxDate.toISOString().split("T")[0]}
          size="small"
          sx={{ width: 150 }}
        />
      </Stack>
    );
  }

  return (
    <Stack
      direction="row"
      spacing={2}
      height="100%"
      alignItems="center"
      sx={{
        pl: 1,
        pr: 1,
      }}
    >
      <TextField
        fullWidth
        type="date"
        value={minDate.toISOString().split("T")[0]}
        size="small"
        sx={{ width: 220 }}
      />
      <Slider
        // orientation="vertical"
        track={false}
        // value={sliderValue}
        // onChange={handleSliderChange}
        min={new Date(minDate.setHours(0, 0, 0, 0)).getTime()}
        max={new Date(maxDate.setHours(24, 0, 0, 0)).getTime()}
        valueLabelDisplay="on"
        step={
          // 15 minutes
          15 * 60 * 1000
        }
        valueLabelFormat={(value) => {
          return new Date(value)
            .toLocaleString("en-GB", {
              month: "short",
              day: "2-digit",
              hour: "2-digit",
              minute: "2-digit",
              hour12: false,
            })
            .replace(",", "");
        }}
        sx={{ width: "100%" }}
      />
      <TextField
        fullWidth
        type="date"
        value={maxDate.toISOString().split("T")[0]}
        size="small"
        sx={{ width: 220 }}
      />
    </Stack>
  );
};
