import {
  Box,
  Divider,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useApi } from "../../containers/ApiContainer";
import { useLocationsState } from "../../containers/LocationsStateContainer";
import { useLayout } from "../../screens/Ramp/CollapsibleRampActionCard";
import { AddTenantButton } from "../../screens/Settings/HangarSettings/AddTenantButton/AddTenantButton";
import {
  Hangar,
  Location,
  Ramp,
  Stack as StackType,
  Tenant,
  Trip,
} from "../../types";
import { scrollSx, uuidv4 } from "../../utils";
import { AircraftListItem } from "./AircraftListItem";
import { AircraftPreferencesListItemMenuButton } from "./AircraftPreferencesListItemMenu";

type Props = {
  isAlgorithmTesting?: boolean;
  schedule?: boolean;
  location: Location<Hangar | Ramp>;
  setLocation: (location: Location<Hangar | Ramp>) => void;
  stack: StackType;
  setStack: (stack: StackType) => void;
  width?: number;
  readOnly?: boolean;
  feetToPixels?: number;
};

const sortTenants = (tenants: Tenant[]): Tenant[] => {
  return tenants.sort((a: Tenant, b: Tenant) => {
    // if (a.selected) {
    //   return -1;
    // }
    // if (b.selected) {
    //   return 1;
    // }
    if (!a.tail_number) {
      return 1;
    }
    if (!b.tail_number) {
      return -1;
    }
    if (!a.tail_number?.toLowerCase().startsWith("n")) {
      return 1;
    }
    if (!b.tail_number?.toLowerCase().startsWith("n")) {
      return -1;
    }
    if (a.tail_number > b.tail_number) {
      return 1;
    }
    if (a.tail_number < b.tail_number) {
      return -1;
    }
    return 1;
  });
};

export const CurrentAircraft: React.FC<Props> = ({
  location,
  setLocation,
  stack,
  setStack,
  width,
  feetToPixels,
  readOnly = false,
  schedule = false,
}) => {
  const theme = useTheme();
  const { postgrest } = useApi();
  const { isDrawerOpen } = useLayout();
  const { sendToLocation } = useLocationsState();
  const sortedTenants = React.useMemo(
    () =>
      [...stack.tenants]
        .sort((a, b) => {
          if (a.selected) {
            return -1;
          }
          if (b.selected) {
            return 1;
          }
          return a.tail_number.localeCompare(b.tail_number);
        })
        .filter((t) => t.position.stack_id === stack.id)
        .filter((tenant) => tenant.aircraft?.make),
    [stack.tenants]
  );

  // when a tenant gets added to the hangar, we need to update the stack or stacks
  const onAddAircraft = React.useCallback(
    async (tenant: Tenant | Tenant[], trip?: Trip) => {
      // convert to an array if it's not already
      const tenants = Array.isArray(tenant) ? tenant : [tenant];
      // there is a wrinkle here. if the user is adding tenant(s) with a trip, then
      // we need to also add the tenant to each stack within the time range of the trip
      if (trip) {
        const stacksWithinTrip = location.stacks.filter((s) => {
          if (!s.snapshot_at) {
            return false;
          }
          const snapshotAt = new Date(s.snapshot_at);
          return snapshotAt >= trip.arrival && snapshotAt <= trip.departure;
        });

        let updatedStack = stack;
        // check to see if we need to add it to the current stack
        if (stacksWithinTrip.some((s) => s.id === stack.id)) {
          updatedStack = {
            ...location.stack,
            tenants: [...tenants, ...stack.tenants],
          };
        }

        const stacks = location.stacks.map((s) => {
          if (stacksWithinTrip.some((st) => st.id === s.id)) {
            const tenantsWithUniquePositions = tenants.map((t) => ({
              ...t,
              position: {
                ...t.position,
                stack_id: s.id,
                id: uuidv4(),
              },
            }));
            return {
              ...s,
              tenants: [...s.tenants, ...tenantsWithUniquePositions],
            };
          }
          return s;
        });

        // fix the position so its unique per stack
        // trip needs to be unique across stacks
        setLocation({
          ...location,
          stack: updatedStack,
          stacks,
        });
        console.log("Forward filling tenants", tenants);
        for (const t of tenants) {
          // and we also need to update any stacks not currently in memory. we'll do this by calling
          // a stored procedure that will update the stacks in the database
          // await postgrest.rpc("forward_fill_schedule", {
          //   zentity_id: t.entity_id,
          //   location_id: location.location_id,
          //   start_dt: trip.arrival,
          //   end_dt: trip.departure,
          //   x: t.position.x,
          //   y: t.position.y,
          //   angle: t.position.angle,
          // });
        }
        return;
      }
      setLocation({
        ...location,
        stack: {
          ...location.stack,
          tenants: [...tenants, ...stack.tenants],
        },
      });
    },
    [location, setLocation]
  );

  const setTenant = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.map((t) => (t.id === tenant.id ? tenant : t)),
      });
    },
    [stack, setStack]
  );

  const onDeleteAircraft = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.filter(
          (t) => t.placement_id !== tenant.placement_id
        ),
      });
    },
    [stack, setStack]
  );

  const onSelectAircraft = React.useCallback(
    (tenant: Tenant) => {
      setStack({
        ...stack,
        tenants: stack.tenants.map((t) => ({
          ...t,
          selected:
            t.placement_id === tenant.placement_id ? !t.selected : false,
        })),
      });
    },
    [stack, setStack]
  );

  if (!isDrawerOpen) {
    return (
      <Stack
        direction="column"
        alignItems="center"
        spacing={2}
        sx={{
          width: "100%",
          height: "100%",
          overflow: "hidden",
        }}
      >
        <AddTenantButton
          schedule={schedule}
          iconButton
          disabled={readOnly}
          onAdd={onAddAircraft}
          location={location}
          sendToLocation={sendToLocation}
          stack={stack}
          setStack={setStack}
          feetToPixels={feetToPixels}
        />
        <Divider flexItem />
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            overflowY: "scroll",
            overflowX: "hidden",
            width: "100%",
            ...scrollSx,
          }}
        >
          {sortedTenants.map((tenant, idx) => (
            <Tooltip
              key={`current-aircraft-${tenant.id}`}
              placement="right"
              title={`${tenant.tail_number || "N/A"} • ${
                tenant.aircraft.model
              }`}
            >
              <Stack
                key={`current-aircraft-${tenant.id}`}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
                p={1}
                sx={{
                  width: "100%",
                  cursor: "pointer",
                  bgcolor: tenant.selected
                    ? `${theme.palette.success.main}15`
                    : "transparent",
                  "&:hover": {
                    backgroundColor: `${theme.palette.success.main}15`,
                  },
                }}
              >
                <Typography
                  variant="body2"
                  noWrap
                  maxWidth={60}
                  onClick={() => onSelectAircraft(tenant)}
                >
                  {tenant.tail_number || tenant.aircraft.model}
                </Typography>
                <AircraftPreferencesListItemMenuButton
                  stack={stack}
                  setStack={setStack}
                  tenant={tenant}
                  setTenant={setTenant}
                  preferences={tenant.position.preferences}
                  setPreferences={(preferences) => {
                    setTenant({
                      ...tenant,
                      position: {
                        ...tenant.position,
                        preferences,
                      },
                    });
                  }}
                  onDeleteAircraft={() => onDeleteAircraft(tenant)}
                />
              </Stack>
            </Tooltip>
          ))}
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        minWidth: width ?? 400,
        height: "100%",
        overflow: "hidden",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ pr: 1, pl: 1 }}
      >
        <Typography>Current Aircraft</Typography>
        <AddTenantButton
          schedule={schedule}
          disabled={readOnly}
          onAdd={onAddAircraft}
          location={location}
          sendToLocation={sendToLocation}
          stack={stack}
          setStack={setStack}
          feetToPixels={feetToPixels}
        />
      </Stack>
      <Box
        sx={{
          flex: 1,
          height: "100%",
          width: "100%",
          overflowY: "scroll",
          overflowX: "hidden",
          ...scrollSx,
        }}
      >
        {sortedTenants.map((tenant, idx) => (
          <React.Fragment key={`current-aircraft-${tenant.id}`}>
            <AircraftListItem
              schedule={schedule}
              showBottomBorder={idx + 1 === stack.tenants.length}
              stack={stack}
              setStack={setStack}
              tenant={tenant}
              setTenant={setTenant}
              onSelectAircraft={onSelectAircraft}
              onDeleteAircraft={onDeleteAircraft}
            />
          </React.Fragment>
        ))}
      </Box>
    </Stack>
  );
};
