import { Grid2, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { uniqBy } from "lodash";
import * as React from "react";
import { useLocationsState } from "../../../../containers/LocationsStateContainer";
import { useSnackbar } from "../../../../containers/SnackbarContainer";
import { useTenants } from "../../../../hooks/useTenants";
import { Hangar, Location, Ramp, Tenant, Trip } from "../../../../types";
import { addTenantConformationText } from "../../../../utils/phrasing";
import { LocationTextField } from "../../../../widgets/LocationTextField";
import { SelectTailNumber } from "../../../../widgets/SelectTailNumber";
import { Aircraft } from "../../../LabelingTool";
import { AutocompleteAircraft } from "../AutocompleteAircraft";

interface DateSectionProps {
  date: Date;
  onChange: (date: Date) => void;
  label: string;
}

export const DateSection: React.FC<DateSectionProps> = ({
  date,
  onChange,
  label,
}) => {
  const timeOptions = Array.from({ length: 24 * 4 }, (_, i) => {
    const hours = Math.floor(i / 4);
    const minutes = (i % 4) * 15;
    return {
      value: i,
      label: `${hours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`,
    };
  });

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newDate = new Date(date);
    const [year, month, day] = e.target.value.split("-").map(Number);
    newDate.setFullYear(year);
    newDate.setMonth(month - 1);
    newDate.setDate(day);
    onChange(newDate);
  };

  const handleTimeChange = (timeValue: number) => {
    const newDate = new Date(date);
    const hours = Math.floor(timeValue / 4);
    const minutes = (timeValue % 4) * 15;
    newDate.setHours(hours, minutes, 0);
    onChange(newDate);
  };

  const getCurrentTimeValue = () => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    return hours * 4 + Math.floor(minutes / 15);
  };

  return (
    <Grid2 container spacing={1} alignItems="center">
      <Grid2 size={{ xs: 7 }}>
        <TextField
          fullWidth
          type="date"
          label={label}
          value={date.toISOString().split("T")[0]}
          onChange={handleDateChange}
          size="small"
        />
      </Grid2>
      <Grid2 size={{ xs: 5 }}>
        <TextField
          select
          fullWidth
          size="small"
          value={getCurrentTimeValue()}
          onChange={(e) =>
            handleTimeChange((e.target.value as unknown) as number)
          }
        >
          {timeOptions.map((time) => (
            <MenuItem key={time.value} value={time.value}>
              {time.label}
            </MenuItem>
          ))}
        </TextField>
      </Grid2>
    </Grid2>
  );
};

type Props = {
  disableAutocomplete: boolean;
  tailNumber: string;
  setTailNumber: (tailNumber: string) => void;
  owner: string;
  setOwner: (owner: string) => void;
  location: Location<Hangar | Ramp>;
  setShowConfirmation: (message: string) => void;
  type: string;
  setType: (type: string) => void;
  isReference: boolean;
  note: string;
  setNote: (note: string) => void;
  newAircraft: Aircraft;
  setNewAircraft: (aircraft: Aircraft) => void;
  currentLocation: Location<Ramp | Hangar>;
  setCurrentLocation: (l: Location<Ramp | Hangar>) => void;
  assignedLocation: Location<Ramp | Hangar>;
  setAssignedLocation: (l: Location<Ramp | Hangar>) => void;
  isScheduleEnabled: boolean;
  trip: Trip;
  setTrip: (trip: Trip) => void;
};

export const NewAircraftForm: React.FC<Props> = ({
  disableAutocomplete = false,
  tailNumber,
  setTailNumber,
  owner,
  setOwner,
  type,
  setType,
  location,
  setShowConfirmation,
  isReference,
  newAircraft,
  setNewAircraft,
  note,
  setNote,
  assignedLocation,
  setAssignedLocation,
  currentLocation,
  setCurrentLocation,
  trip,
  setTrip,
  isScheduleEnabled = true,
}) => {
  const { tenants } = useTenants();
  const { locations } = useLocationsState();
  const snackbar = useSnackbar();

  // for the time being tail numbers aren't unique. we're going to cheat a bit
  // and dedup on tail number here
  const tenantsWithTailNumber = React.useMemo(
    () =>
      uniqBy(
        tenants.filter((t) => t.tail_number),
        "tail_number"
      ),
    [tenants]
  );

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={2}>
        <SelectTailNumber
          disableAutocomplete={disableAutocomplete}
          defaultValue={tailNumber}
          tenants={tenantsWithTailNumber}
          onInputChange={(tailNumber: string) => {
            setTailNumber(tailNumber);
          }}
          onChange={(tenant: Tenant) => {
            setNewAircraft(tenant?.aircraft);
            setType(tenant?.type ?? "");
            setTailNumber(tenant?.tail_number ?? "");
            setOwner(tenant?.owner_manager ?? "");
          }}
        />

        <TextField
          inputProps={{ testid: "owner_manager" }}
          fullWidth
          size="small"
          label="Owner/Manager"
          onChange={(evt) => setOwner(evt.target.value)}
          value={owner}
        />
        <TextField
          select
          size="small"
          fullWidth
          label="Type"
          onChange={(evt) => {
            if (evt.target.value === "base") {
              const confirmationText = addTenantConformationText(
                !location || location?.type === "hangar" ? "hangar" : "ramp"
              );
              setShowConfirmation(confirmationText);
              return;
            }
            setType(evt.target.value);
          }}
          value={type}
        >
          {(!location || isReference) && <MenuItem value="base">Base</MenuItem>}
          {(!location || !isReference) && (
            <MenuItem value="transient">Transient</MenuItem>
          )}
        </TextField>
      </Stack>
      <AutocompleteAircraft
        label="Search by make and model"
        onSelect={(aircraft) => setNewAircraft(aircraft)}
        defaultSelected={newAircraft}
      />
      {/* dates go here */}
      {isScheduleEnabled && (
        <Stack direction="column" spacing={1}>
          <Typography variant="subtitle1">Dates and Times</Typography>
          <Grid2 container spacing={1}>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <DateSection
                date={trip.arrival!}
                onChange={(date) => {
                  const newDepartureDate = date;
                  newDepartureDate.setDate(date.getDate() + 2);
                  setTrip({
                    ...trip,
                    arrival: date,
                    hangar_in: date,
                    departure: new Date(
                      Math.max(+trip.departure, +newDepartureDate)
                    ),
                    hangar_out: new Date(
                      Math.max(+trip.hangar_out, +newDepartureDate)
                    ),
                  });
                }}
                label="Arrival"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <DateSection
                date={trip.hangar_in!}
                onChange={(date) => {
                  if (
                    date > trip.arrival! &&
                    date < trip.hangar_out! &&
                    date < trip.departure!
                  ) {
                    setTrip({ ...trip, hangar_in: date });
                  } else {
                    // Optionally, you can show an error message here
                    console.error(
                      "Hangar In date must be > Arrival and < Hangar Out and < Departure"
                    );
                    snackbar.notify({
                      text:
                        "Hangar In date must be after Arrival and before Hangar Out/Departure.",
                      severity: "error",
                    });
                  }
                }}
                label="Hangar In"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <DateSection
                date={trip.departure!}
                onChange={(date) => {
                  if (date >= trip.arrival!) {
                    setTrip({ ...trip, departure: date, hangar_out: date });
                  } else {
                    // Optionally, you can show an error message here
                    console.error(
                      "Departure date cannot be before Arrival date"
                    );
                    snackbar.notify({
                      text: "Departure date cannot be before Arrival date.",
                      severity: "error",
                    });
                  }
                }}
                label="Departure"
              />
            </Grid2>
            <Grid2 size={{ xs: 12, sm: 6 }}>
              <DateSection
                date={trip.hangar_out!}
                onChange={(date) => {
                  if (
                    date <= trip.departure! &&
                    date >= trip.arrival! &&
                    date >= trip.hangar_in!
                  ) {
                    setTrip({ ...trip, hangar_out: date });
                  } else {
                    // Optionally, you can show an error message here
                    console.error(
                      "Hangar Out date must be <= Departure and >= Arrival and >= Hangar In"
                    );
                    snackbar.notify({
                      text:
                        "Hangar Out date must be before Departure and after Arrival/Hangar In.",
                      severity: "error",
                    });
                  }
                }}
                label="Hangar Out"
              />
            </Grid2>
          </Grid2>
        </Stack>
      )}

      <TextField
        autoFocus
        margin="dense"
        label="Notes"
        type="text"
        fullWidth
        placeholder="Departure Time: 05/24/2025 12:00 PM"
        multiline
        minRows={5}
        onChange={(evt) => setNote(evt.target.value)}
        value={note ?? ""}
      />
      {!location && type === "base" && (
        <Stack direction="row" spacing={2} sx={{ width: "100%" }}>
          <LocationTextField
            isReference={true}
            unassignedText={type === "base" ? "Unassigned" : "N/A"}
            selectedLocationId={assignedLocation?.location_id}
            onChange={(newLocationId) => {
              const newLocation = locations.find(
                (l) => l.location_id === newLocationId
              );
              setAssignedLocation(newLocation);
            }}
          />
          <LocationTextField
            isReference={false}
            selectedLocationId={currentLocation?.location_id}
            unassignedText="Not Hangared"
            onChange={(newLocationId) => {
              const newLocation = locations.find(
                (l) => l.location_id === newLocationId
              );
              setCurrentLocation(newLocation);
            }}
          />
        </Stack>
      )}
    </Stack>
  );
};
