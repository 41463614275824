import AddIcon from "@mui/icons-material/Add";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Tooltip,
} from "@mui/material";
import * as React from "react";
import { useActiveFBOs } from "../../../../containers/ActiveFBOContainer";
import { useIdentity } from "../../../../containers/IdentityContainer";
import { LocationsContainer } from "../../../../containers/LocationsStateContainer";
import { useMultiHangarState } from "../../../../containers/MultiHangarContainer";
import { useTenants } from "../../../../hooks/useTenants";
import { getStackPolygons } from "../../../../hooks/utils";
import {
  Hangar,
  Location,
  Ramp,
  Stack as StackType,
  Tenant,
  TenantType,
  Trip,
} from "../../../../types";
import {
  consistentId,
  defaultPreferenceForAircraft,
  uuidv4,
} from "../../../../utils";
import mixpanel from "../../../../utils/mixpanel";
import { addTenantConformationText } from "../../../../utils/phrasing";
import { AddMovableObstacle } from "../../../../widgets/AddMovableObstacle/AddMovableObstacle";
import { ConfirmationDialog } from "../../../../widgets/ConfirmationDialog";
import { doBoundsIntersect } from "../../../../widgets/Layout/widgets/CollisionOverlay";
import { Aircraft } from "../../../LabelingTool";
import { useLayoutCanvasState } from "../../../Ramp/RampPresenter";
import { AddSelectedTenant } from "../AddSelectedTenant";
import { BulkAdd } from "../BulkAdd";
import { TenantSelector } from "../TenantSelector";
import { NewAircraftForm } from "./NewAircraftForm";

// if the bottom of the hangar/ramp doesn't appear in the current viewport, then
// we want to place the aircraft in the bottom left corner of the viewport relative
// to the hangar/ramp
export const calculateInitialPosition = (
  location: Location<Hangar | Ramp>,
  newAircraft: Aircraft,
  feetToPixels?: number,
  x0?: number,
  y0?: number
) => {
  if (!location || !newAircraft) {
    return;
  }

  if (feetToPixels) {
    // check to see if the entire location is within the viewport
    const y =
      y0 > 0
        ? y0
        : -1 * Math.max(newAircraft.length, newAircraft.wingspan) + y0;

    // for the x position, we don't want to place the aircraft on top of another aircraft
    const polygons = getStackPolygons(
      location.stack,
      1e8,
      feetToPixels,
      null,
      false
    );
    // increment the x position until we find a spot that doesn't intersect with any other aircraft
    let x = x0 || 0;
    while (
      polygons.some((polygon) =>
        doBoundsIntersect(polygon.bounds, [
          [x, y],
          [x + newAircraft.wingspan, y + newAircraft.length],
        ])
      )
    ) {
      x += 10;
    }

    return {
      x,
      y,
    };
  }

  return {
    x: null,
    y: -1 * Math.max(newAircraft.length, newAircraft.wingspan),
  };
};

export const checkForAircraftWarnings = (
  aircraft: Aircraft,
  location: Location<Hangar | Ramp>
) => {
  if (!location) {
    return;
  }

  // make sure we have a height to check
  if (!("height" in location)) {
    return;
  }
  // if it's a hangar, then run these checks...
  if (!location.height || !aircraft?.tail_height) {
    return;
  }

  const usableHangarWidth =
    location.width - (location.left_door ?? 0) - (location.right_door ?? 0);
  const newWarnings = [];
  if (aircraft.tail_height >= location.height) {
    newWarnings.push(
      `The aircraft is ${aircraft.tail_height}ft tall and your hangar door height is ${location.height}ft tall. This aircraft will not fit.`
    );
  } else if (aircraft.tail_height + 1 >= location.height) {
    newWarnings.push(
      `The aircraft is ${aircraft.tail_height}ft tall and your hangar door height is ${location.height}ft tall. Tow with extra caution.`
    );
  }
  if (aircraft.wingspan >= usableHangarWidth) {
    newWarnings.push(
      `The aircraft wingspan is ${aircraft.wingspan}ft and your hangar door is ${usableHangarWidth}ft wide. This aircraft will not fit.`
    );
  } else if (aircraft.wingspan + 1 >= usableHangarWidth) {
    newWarnings.push(
      `The aircraft wingspan is ${aircraft.wingspan}ft and your hangar door is ${usableHangarWidth}ft wide. Tow with extra caution.`
    );
  }
  return newWarnings;
};

/**
 * we need a wrapper b/c i need the useLocationsState container to be able to grab the
 * findTenantLocation function
 */
export const AddTenantButton: React.FC<Props> = (props) => (
  <LocationsContainer>
    <AddTenantButtonPresenter {...props} />
  </LocationsContainer>
);

type Props = {
  text?: string;
  location: Location<Hangar | Ramp>;
  sendToLocation: (
    location: Location<Ramp | Hangar>,
    stackName: string,
    tenant: Tenant
  ) => void;
  stack: StackType;
  setStack: (stack: StackType) => void;
  onAdd: (tenant: Tenant | Tenant[], trip?: Trip) => void;
  hideAddMovableObstacle?: boolean;
  bulk?: boolean;
  disabled?: boolean;
  feetToPixels?: number;
  schedule?: boolean;
  iconButton?: boolean;
};

export const AddTenantButtonPresenter: React.FC<Props> = ({
  location,
  sendToLocation,
  stack,
  setStack,
  onAdd,
  hideAddMovableObstacle = false,
  bulk = true,
  disabled = false,
  schedule = false,
  feetToPixels,
  iconButton = false,
}) => {
  const { initialPositionOffset } = useLayoutCanvasState();
  const { airplxIdentity } = useIdentity();
  const { tenants, setTenants } = useTenants();
  const [
    localCurrentLocation,
    setLocalCurrentLocation,
  ] = React.useState<Location<Ramp | Hangar> | null>();
  const [
    localAssignedLocation,
    setLocalAssignedLocation,
  ] = React.useState<Location<Ramp | Hangar> | null>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [selectedTab, setSelectedTab] = React.useState<string>("New Aircraft");
  const [warnings, setWarnings] = React.useState<string[]>([]);
  const [tailNumber, setTailNumber] = React.useState<string>("");
  const [owner, setOwner] = React.useState<string>("");
  const [note, setNote] = React.useState<string>("");
  const [type, setType] = React.useState<string>(
    stack?.isReference ? "base" : "transient"
  );
  const [trip, setTrip] = React.useState<Trip>({
    id: uuidv4(),
    tenant_id: "",
    arrival: new Date(new Date().setHours(12, 0, 0, 0)),
    hangar_in: new Date(new Date().setHours(12, 0, 0, 0)),
    departure: new Date(
      new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
        12,
        0,
        0,
        0
      )
    ),
    hangar_out: new Date(
      new Date(new Date().setDate(new Date().getDate() + 1)).setHours(
        12,
        0,
        0,
        0
      )
    ),
  });
  const [bulkTenantsToAdd, setBulkTenantsToAdd] = React.useState<Tenant[]>([]);
  const [newAircraft, setNewAircraft] = React.useState<Aircraft>();
  const [showConfirmation, setShowConfirmation] = React.useState<string>("");
  const { activeLocationId } = useMultiHangarState();
  const { activeFBO } = useActiveFBOs();

  /**
   * not sure where to put this...
   */
  const checkForWarnings = React.useCallback(
    (aircraft: Aircraft) => {
      const warnings = checkForAircraftWarnings(aircraft, location);
      if (!warnings) {
        return;
      }
      setWarnings(warnings);
    },
    [location]
  );

  React.useEffect(() => {
    setTailNumber("");
    setOwner("");
    setType(stack?.isReference ? "base" : "transient");
    setNote("");
    setWarnings([]);
    setNewAircraft(null);
    setLocalAssignedLocation(null);
    setLocalCurrentLocation(null);
    setBulkTenantsToAdd([]);
  }, [open]);

  React.useEffect(() => {
    checkForWarnings(newAircraft);
  }, [newAircraft]);

  React.useEffect(() => {
    if (Boolean(activeLocationId)) {
      return;
    }
    if (!schedule && stack?.tenants?.length === 0) {
      setOpen(true);
      setSelectedTab("Tenants");
    }
  }, [stack?.tenants?.length]);

  const onClickAddTenant = React.useCallback(async () => {
    if (!newAircraft) {
      return;
    }
    if (type === "base" && (location || selectedTab !== "New Aircraft")) {
      setShowConfirmation(
        addTenantConformationText(
          !location || location?.type === "hangar" ? "hangar" : "ramp"
        )
      );
      return;
    }
    const new_tenant_id = uuidv4();
    trip.tenant_id = new_tenant_id;
    const entity_id = uuidv4();
    const tenant: Tenant = {
      id: new_tenant_id,
      placement_id: uuidv4(),
      fbo_id: activeFBO.id,
      entity_id,
      owner_manager: owner,
      tail_number: tailNumber,
      note,
      note_last_updated: new Date().toISOString(),
      note_last_updated_by: airplxIdentity?.email ?? "Unknown",
      type: stack?.isReference ? "base" : type,
      aircraft_id: newAircraft.id,
      aircraft: newAircraft,
      selected: false,
      position: {
        id: consistentId(entity_id, stack?.isReference ?? false),
        stack_id: stack?.id,
        entity_id,
        ...calculateInitialPosition(
          location,
          newAircraft,
          feetToPixels,
          initialPositionOffset.xFeet,
          initialPositionOffset.yFeet
        ),
        angle: null,
        preferences: defaultPreferenceForAircraft(newAircraft, activeFBO),
      },
      trips: [trip],
    };

    onAdd(tenant, trip);
    setTenants(tenants.concat([tenant]));
    setNewAircraft(null);
    setOpen(false);
    // i don't like this but there isn't a great alternative. we need to wait for the tenant
    // to be created, then put it into the correct hangars
    setTimeout(async () => {
      if (Boolean(localCurrentLocation)) {
        await sendToLocation(localCurrentLocation, "current", tenant);
      }
      if (Boolean(localAssignedLocation)) {
        await sendToLocation(localAssignedLocation, "reference", tenant);
      }
    }, 100);
    mixpanel.track("New Aircraft", {
      aircraft_tenant_type: type,
      aircraft_type: newAircraft.model,
      aircraft_tail_number: tenant.tail_number,
    });
  }, [
    location,
    stack,
    type,
    tailNumber,
    owner,
    note,
    newAircraft,
    onAdd,
    sendToLocation,
    tenants,
    feetToPixels,
    initialPositionOffset,
  ]);

  const onClickBulkAdd = async () => {
    // bulk add
    setTenants([...tenants, ...bulkTenantsToAdd]);
    onAdd(bulkTenantsToAdd);
    // i don't like this but there isn't a great alternative. we need to wait for the tenant
    // to be created, then put it into the correct hangars
    setTimeout(async () => {
      if (Boolean(localCurrentLocation)) {
        for (const tenant of bulkTenantsToAdd) {
          await sendToLocation(localCurrentLocation, "current", tenant);
        }
      }
      if (Boolean(localAssignedLocation)) {
        for (const tenant of bulkTenantsToAdd) {
          await sendToLocation(localAssignedLocation, "reference", tenant);
        }
      }
    }, 100);
    setOpen(false);
  };

  const selectedTenant = React.useMemo(() => {
    return tenants
      ?.filter((t) => t.tail_number)
      .find(
        (t) =>
          t.tail_number.toLocaleUpperCase() === tailNumber.toLocaleUpperCase()
      );
  }, [tenants, tailNumber]);

  const onAddSelectedTenant = React.useCallback(
    async (tenant: Tenant) => {
      if (!showConfirmation && location?.stack?.isReference) {
        setShowConfirmation(
          !location || location?.type === "hangar"
            ? "We'll assign this new Base Tenant to this hangar. If you need to change default hangar assignments, you can do that by editing this specific aircraft."
            : "We'll assign this new Tie-Down Tenant to this ramp. If you need to change default ramp assignments, you can do that by editing this specific aircraft."
        );
        return;
      }
      const entity_id = tenant.entity_id ?? uuidv4();
      const updatedTenant: Tenant = {
        ...tenant,
        entity_id,
        type: stack?.isReference ? "base" : tenant?.type ?? "transient",
        selected: false,
        position: {
          id: uuidv4(),
          entity_id: tenant.entity_id,
          stack_id: stack?.id,
          ...calculateInitialPosition(
            location,
            newAircraft,
            null,
            initialPositionOffset.xFeet,
            initialPositionOffset.yFeet
          ),
          angle: null,
          preferences: defaultPreferenceForAircraft(tenant.aircraft, activeFBO),
        },
      };

      if (sendToLocation) {
        await sendToLocation(location, stack.name, updatedTenant);
      }
      onAdd({
        ...updatedTenant,
      });
      mixpanel.track("Add Aircraft", {
        aircraft_tenant_type: type,
        aircraft_type: newAircraft?.model,
        aircraft_tail_number: updatedTenant?.tail_number,
      });
      setNewAircraft(null);
      setTailNumber("");
      setOwner("");
      setType(stack?.isReference ? "base" : "transient");
      setNote("");
      setWarnings([]);
      setOpen(false);
    },
    [location, stack, onAdd, type, newAircraft, initialPositionOffset]
  );

  const onAddTrip = React.useCallback(
    async (tenant: Tenant) => {
      const trips = [...(tenant.trips ?? [])];
      trips.push({
        ...trip,
        tenant_id: tenant.id,
      });
      const entity_id = tenant?.entity_id ?? uuidv4();
      const tenantWithNewTrip: Tenant = {
        ...tenant,
        trips,
        position: {
          id: consistentId(entity_id, stack?.isReference ?? true),
          stack_id: stack?.id,
          entity_id,
          ...calculateInitialPosition(
            location,
            newAircraft,
            null,
            initialPositionOffset.xFeet,
            initialPositionOffset.yFeet
          ),
          angle: null,
          preferences: defaultPreferenceForAircraft(tenant.aircraft, activeFBO),
        },
      };
      mixpanel.track("Add Trip", {
        aircraft_tenant_type: type,
        aircraft_type: newAircraft?.model,
        aircraft_tail_number: tenantWithNewTrip?.tail_number,
      });

      setTenants(
        tenants.map((t) => (t.id === tenant.id ? tenantWithNewTrip : t))
      );
      setStack({
        ...stack,
        tenants: stack.tenants.map((t) =>
          t.id === tenant.id ? tenantWithNewTrip : t
        ),
      });
      setLocalCurrentLocation;
      setNewAircraft(null);
      setTailNumber("");
      setOwner("");
      setType(stack?.isReference ? "base" : "transient");
      setNote("");
      setWarnings([]);
      setOpen(false);
    },
    [tenants, trip]
  );

  const buttonText = schedule ? "Add Trip" : "Add Aircraft";

  return (
    <>
      {iconButton ? (
        <Tooltip title={buttonText}>
          <Button
            size="small"
            variant="contained"
            color="info"
            onClick={async () => setOpen(true)}
            sx={{
              alignSelf: "center",
              width: "80%",
            }}
          >
            <Stack direction="row" spacing={1}>
              <AirplanemodeActiveIcon />
              <AddIcon />
            </Stack>
          </Button>
        </Tooltip>
      ) : (
        <Button
          disabled={disabled}
          variant="contained"
          startIcon={<AddIcon />}
          onClick={async () => setOpen(true)}
          color={"info"}
        >
          {buttonText}
        </Button>
      )}
      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
        <DialogTitle>Add Aircraft</DialogTitle>
        <DialogContent sx={{ width: 900, height: 600 }}>
          <Stack spacing={4} sx={{ height: "100%" }}>
            <Stack direction="column" spacing={2} sx={{ height: "100%" }}>
              {warnings.map((warning, idx) => (
                <Alert
                  key={`warning-${idx}`}
                  severity="error"
                  action={
                    <IconButton
                      size="small"
                      onClick={() =>
                        setWarnings(warnings.filter((w, i) => i !== idx))
                      }
                    >
                      <CloseIcon fontSize="inherit" />
                    </IconButton>
                  }
                >
                  {warning}
                </Alert>
              ))}
              <Tabs
                value={selectedTab}
                onChange={(evt, newValue) => setSelectedTab(newValue)}
                aria-label="basic tabs example"
              >
                <Tab value="New Aircraft" label="New Aircraft" />
                {Boolean(location) && (
                  <Tab
                    value="Prior Transients"
                    label="Prior Transients"
                    disabled={schedule}
                  />
                )}
                {Boolean(location) && (
                  <Tab value="Tenants" label="Tenants" disabled={schedule} />
                )}
                {bulk && (
                  <Tab value="Bulk Add" label="Bulk Add" disabled={schedule} />
                )}
              </Tabs>
              {selectedTab === "New Aircraft" && (
                <>
                  <NewAircraftForm
                    disableAutocomplete={false}
                    tailNumber={tailNumber}
                    setTailNumber={setTailNumber}
                    owner={owner}
                    setOwner={setOwner}
                    location={location}
                    setShowConfirmation={setShowConfirmation}
                    type={type}
                    setType={setType}
                    note={note}
                    setNote={setNote}
                    isReference={stack?.isReference}
                    newAircraft={newAircraft}
                    setNewAircraft={setNewAircraft}
                    assignedLocation={localAssignedLocation}
                    setAssignedLocation={setLocalAssignedLocation}
                    currentLocation={localCurrentLocation}
                    setCurrentLocation={setLocalCurrentLocation}
                    isScheduleEnabled={schedule}
                    trip={trip}
                    setTrip={setTrip}
                  />
                  {Boolean(selectedTenant) && (
                    <AddSelectedTenant
                      selectedTenant={selectedTenant}
                      onAdd={onAddSelectedTenant}
                      onAddTrip={onAddTrip}
                      isReference={stack?.isReference}
                      location={location}
                    />
                  )}
                </>
              )}
              {selectedTab === "Prior Transients" && (
                <TenantSelector
                  tenantType={TenantType.TRANSIENT}
                  filterBy={(t) => t.type === "transient"}
                  isReference={stack?.isReference}
                  checkAircraftHeight={checkForWarnings}
                  location={location}
                  stack={stack}
                  setStack={setStack}
                  feetToPixels={feetToPixels}
                />
              )}
              {selectedTab === "Tenants" && (
                <TenantSelector
                  tenantType={TenantType.BASE}
                  filterBy={(t) => t.type === "base"}
                  isReference={stack?.isReference}
                  checkAircraftHeight={checkForWarnings}
                  stack={stack}
                  setStack={setStack}
                  location={location}
                  feetToPixels={feetToPixels}
                />
              )}
              {selectedTab === "Bulk Add" && (
                <BulkAdd
                  stack={stack}
                  bulkTenantsToAdd={bulkTenantsToAdd}
                  setBulkTenantsToAdd={setBulkTenantsToAdd}
                />
              )}
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack
            direction="row"
            justifyContent="space-between"
            sx={{ width: "100%" }}
          >
            {hideAddMovableObstacle ? (
              <div />
            ) : (
              <AddMovableObstacle
                location={location}
                stack={stack}
                setStack={setStack}
              />
            )}
            <Stack direction="row" spacing={1}>
              <Button onClick={() => setOpen(false)}>Cancel</Button>
              {selectedTab === "New Aircraft" || selectedTab === "Bulk Add" ? (
                <Button
                  disabled={
                    (selectedTab === "New Aircraft" &&
                      (Boolean(selectedTenant) || !Boolean(newAircraft))) ||
                    (selectedTab === "Bulk Add" &&
                      bulkTenantsToAdd.length === 0)
                  }
                  variant="contained"
                  color="success"
                  onClick={() => {
                    if (selectedTab === "New Aircraft") {
                      onClickAddTenant();
                    } else {
                      // bulk add
                      onClickBulkAdd();
                    }
                  }}
                >
                  Add
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => setOpen(false)}
                >
                  Done
                </Button>
              )}
            </Stack>
          </Stack>
        </DialogActions>
      </Dialog>
      {showConfirmation && (
        <ConfirmationDialog
          title="Please Confirm"
          text={showConfirmation}
          onCancel={() => {
            // do nothing
          }}
          onClose={() => {
            setShowConfirmation("");
          }}
          onOk={() => {
            if (!newAircraft) {
              setShowConfirmation("");
              setType("base");
              return;
            }
            const aircraft = selectedTenant?.aircraft ?? newAircraft;
            const entity_id = selectedTenant?.entity_id ?? uuidv4();
            const tenant: Tenant = {
              id: selectedTenant?.id ?? uuidv4(),
              fbo_id: activeFBO?.id,
              placement_id: uuidv4(),
              entity_id: selectedTenant?.entity_id ?? entity_id,
              owner_manager: selectedTenant?.owner_manager ?? owner,
              tail_number: selectedTenant?.tail_number ?? tailNumber,
              type: stack?.isReference ? "base" : type,
              aircraft_id: aircraft.id,
              aircraft: aircraft,
              selected: false,
              position: {
                id: consistentId(entity_id, stack?.isReference ?? true),
                stack_id: stack?.id,
                entity_id,
                ...calculateInitialPosition(
                  location,
                  newAircraft,
                  null,
                  initialPositionOffset.xFeet,
                  initialPositionOffset.yFeet
                ),
                angle: null,
                preferences: defaultPreferenceForAircraft(aircraft, activeFBO),
              },
            };

            onAdd(tenant);
            setNewAircraft(null);
            setShowConfirmation("");
            setOpen(false);
            // i don't like this but there isn't a great alternative. we need to wait for the tenant
            // to be created, then put it into the correct hangars
            setTimeout(async () => {
              if (Boolean(localCurrentLocation)) {
                await sendToLocation(localCurrentLocation, "current", tenant);
              }
              if (Boolean(localAssignedLocation)) {
                await sendToLocation(
                  localAssignedLocation,
                  "reference",
                  tenant
                );
              }
            }, 100);
          }}
        />
      )}
    </>
  );
};
