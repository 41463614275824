import * as React from "react";
import { useEffect } from "react";
import { getTokenMakerUrl } from ".";
import { useIdentity } from "../containers/IdentityContainer";

type LogLevel = "log" | "error" | "warn" | "info" | "debug";

const sendToBackend = async (
  level: LogLevel,
  message: any[],
  token: string
) => {
  const url = getTokenMakerUrl();
  try {
    await fetch(`${url}/logs`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        level,
        message: message.map((m) =>
          typeof m === "object" ? JSON.stringify(m) : m
        ), // Convert objects to strings for safe transmission
        timestamp: new Date().toISOString(),
      }),
    });
  } catch (err) {
    originalConsole.error("Failed to send log to backend:", err);
  }
};

// Save a reference to the original console methods
const originalConsole = { ...console };

// Override console methods
export const overrideConsole = (token: string) => {
  ["log", "error", "warn", "info", "debug"].forEach((level) => {
    (console as any)[level] = (...args: any[]) => {
      // 1. Log to the original front-end console
      (originalConsole as any)[level](...args);

      // 2. Send the logs to the backend
      sendToBackend(level as LogLevel, args, token);
    };
  });
};

type Props = {
  children: React.ReactNode;
};
export const FlingLogsContainer: React.FC<Props> = ({ children }) => {
  const { airplxToken } = useIdentity();
  useEffect(() => {
    if (window.location.hostname === "localhost") {
      return;
    }
    overrideConsole(airplxToken);
  }, [airplxToken]);

  return <>{children}</>;
};
