import { PostgrestClient } from "@supabase/postgrest-js";
import { sum } from "lodash";
import pLimit from "p-limit";
import { v4 as uuidv4 } from "uuid";
import { Aircraft } from "../screens/LabelingTool";
import {
  CornerStrategy,
  FBO,
  GarageDoor,
  Hangar,
  Obstacle,
  Ramp,
} from "../types";
import { defaultPreferenceForAircraft } from "../utils";
import { PredefinedObstacle } from "./usePredefinedObstacle";

let cache = {};

export const resetAircraftCache = () => {
  cache = {};
};

export const batchFetchAircraft = async (
  postgrest: PostgrestClient,
  aircraftIds: string[],
  fbo: FBO
): Promise<any[]> => {
  const columns = [
    "id",
    "linked_to",
    "make",
    "model",
    "wingspan",
    "length",
    "tail_bottom",
    "tail_height",
    "scaled_geom",
    "scaled_geom_full",
    "geom",
    "pivot_point",
    "nose",
    "centroid",
    "midline",
    "front_wheels",
    "rear_wheels",
    "wheel_rotation_limit",
    "tail_wheel",
    "created_at",
  ].join(",");

  // split aircraftIdsToFetch into chunks of 10 to avoid PostgREST error
  const aircraftIdsToFetch = [];
  const batchsize = 10;
  for (let i = 0; i < aircraftIds.length; i += batchsize) {
    aircraftIdsToFetch.push(aircraftIds.slice(i, i + batchsize));
  }
  const limit = pLimit(5);
  // now fetch each chunk
  const newAircraftsChunksRaw = await Promise.all(
    aircraftIdsToFetch.map(async (chunk) => {
      return limit(async () => {
        const { data: newAircraftsRawChunk } = await postgrest
          .from("aircraft")
          .select(columns)
          .in("id", chunk);
        return newAircraftsRawChunk;
      });
    })
  );

  return newAircraftsChunksRaw.flat();
};

export const fetchAircraftForFBO = async (
  postgrest: PostgrestClient,
  fbo: FBO
): Promise<any[]> => {
  const columns = [
    "id",
    "linked_to",
    "make",
    "model",
    "wingspan",
    "length",
    "tail_bottom",
    "tail_height",
    "scaled_geom",
    "scaled_geom_full",
    "geom",
    "pivot_point",
    "nose",
    "centroid",
    "midline",
    "front_wheels",
    "rear_wheels",
    "wheel_rotation_limit",
    "tail_wheel",
    "created_at",
  ].join(",");
  const { data: aircraft } = await postgrest
    .rpc("aircraft_for_fbo", {
      fbo_id: fbo.id,
    })
    .select(columns);
  return aircraft;
};

export const fetchGeomBuffers = async (
  postgrest: PostgrestClient,
  geoms: string[],
  fbo: FBO,
  isRamp: boolean = false
): Promise<any[]> => {
  const { data: geom_scaled_buffers } = await postgrest.rpc("buffer", {
    geoms,
    radius:
      (isRamp ? fbo.sop_ramp_horizontal_spacing : fbo.sop_horizontal_spacing) ??
      2,
  });
  return geom_scaled_buffers;
};

export const fetchAircraftWithCache = async (
  postgrest: PostgrestClient,
  aircraftIds: string[],
  fbo: FBO
): Promise<Aircraft[]> => {
  const aircrafts: Aircraft[] = [];
  const aircraftIdsToFetch = [];
  for (const aircraftId of aircraftIds) {
    if (cache[aircraftId]) {
      aircrafts.push(cache[aircraftId]);
      continue;
    }
    aircraftIdsToFetch.push(aircraftId);
  }
  if (aircraftIdsToFetch.length === 0) {
    return aircrafts;
  }

  const newAircraftsRaw = await fetchAircraftForFBO(postgrest, fbo);

  const scaled_geom_fulls = newAircraftsRaw.map(
    ({ scaled_geom_full }) => scaled_geom_full
  );

  const geom_scaled_buffers = await fetchGeomBuffers(
    postgrest,
    scaled_geom_fulls,
    fbo,
    false
  );

  const ramp_geom_scaled_buffers = await fetchGeomBuffers(
    postgrest,
    scaled_geom_fulls,
    fbo,
    true
  );

  const newAircraftsRawWithBufferedGeom = newAircraftsRaw.map(
    (newAircraftRaw, idx) => ({
      ...newAircraftRaw,
      geom_scaled_buffer: geom_scaled_buffers[idx],
      ramp_geom_scaled_buffer: ramp_geom_scaled_buffers[idx],
    })
  );

  const newAircrafts: Aircraft[] = newAircraftsRawWithBufferedGeom.map(
    (aircraftRaw) => ({
      ...aircraftRaw,
      image:
        window.location.protocol === "http:"
          ? `/${aircraftRaw.id}.png`
          : `https://static.airplx.com/${aircraftRaw.id}.png`,
    })
  );
  // preload the images
  for (const aircraft of newAircrafts) {
    const img = new Image();
    img.setAttribute("crossOrigin", "anonymous");
    img.src = aircraft.image;
  }
  cache = {
    ...cache,
    ...Object.fromEntries(
      newAircrafts.map((aircraft) => [aircraft.id, aircraft])
    ),
  };
  return [...aircrafts, ...newAircrafts];
};

export const fetchPredefinedObstacles = async (
  postgrest: PostgrestClient,
  obstacleIds: string[],
  fbo: FBO
): Promise<PredefinedObstacle[]> => {
  const predefinedObstacles: PredefinedObstacle[] = [];
  const predefinedObstacleIdsToFetch = [];
  for (const obstacleId of obstacleIds) {
    if (cache[obstacleId]) {
      predefinedObstacles.push(cache[obstacleId]);
      continue;
    }
    predefinedObstacleIdsToFetch.push(obstacleId);
  }
  if (predefinedObstacleIdsToFetch.length === 0) {
    return predefinedObstacles;
  }

  const { data: newPredefinedObstaclesRaw } = await postgrest
    .from("predefined_obstacle")
    .select("*")
    .in("id", predefinedObstacleIdsToFetch);

  const newPredefinedObstacles: PredefinedObstacle[] = newPredefinedObstaclesRaw.map(
    (predefinedObstacle) => ({
      ...predefinedObstacle,
      // image: `${aircraftRaw.id}.png`,
    })
  );
  cache = {
    ...cache,
    ...Object.fromEntries(
      newPredefinedObstacles.map((predefinedObstacle) => [
        predefinedObstacle.id,
        predefinedObstacle,
      ])
    ),
  };
  return [...predefinedObstacles, ...newPredefinedObstacles];
};

export const fetchHangarValues = async (
  postgrest: PostgrestClient,
  hangar_location_ids: string[]
): Promise<{ location_id: string; value: number }[]> => {
  const { data } = await postgrest.rpc("nightly_base_tenant_values", {
    hangar_location_ids,
  });
  return data;
};

/**
 * Same as fetchHangars but limit to 1 hangar and return only 1 hangar.
 *
 * @param postgrest
 * @param activeFBO
 * @param hangar_id
 * @param isReference
 * @returns
 */
export const fetchHangar = async (
  postgrest: PostgrestClient,
  activeFBO: FBO,
  hangar_id: string,
  isReference: boolean
): Promise<Hangar> => {
  const hangars = await fetchHangars(postgrest, activeFBO, isReference, [
    hangar_id,
  ]);
  return hangars.find((h) => h.id === hangar_id);
};

export const fetchRamp = async (
  postgrest: PostgrestClient,
  activeFBO: FBO,
  ramp_id: string,
  isReference: boolean,
  includeReferenceImage: boolean = false
): Promise<Ramp> => {
  const ramps = await fetchRamps(
    postgrest,
    activeFBO,
    isReference,
    [ramp_id],
    includeReferenceImage
  );
  return ramps.find((r) => r.id === ramp_id);
};

export const fetchRamps = async (
  postgrest: PostgrestClient,
  activeFBO: FBO,
  isReference: boolean,
  limitToRampIds: string[] = [],
  includeReferenceImage: boolean = false
): Promise<Ramp[]> => {
  let query = postgrest
    .from(includeReferenceImage ? "ramps" : "ramps_without_image")
    .select("*")
    .eq("fbo_id", activeFBO.id)
    .order("name");

  if (limitToRampIds.length) {
    query = query.in("id", limitToRampIds);
  }

  const { data: __rampDataRaw } = await query;
  const rampDataRaw = __rampDataRaw.map((r) => r.ramp);

  const aircraftIds = rampDataRaw
    .map(({ stacks }) =>
      stacks.map((stack) => stack.tenants.map((t) => t.aircraft_id))
    )
    .flat()
    .flat()
    .filter((x) => x);

  const aircrafts = await fetchAircraftWithCache(
    postgrest,
    aircraftIds,
    activeFBO
  );
  const predefinedObstacleIds = rampDataRaw
    .map(({ stacks }) =>
      stacks.map((stack) => stack.movable_obstacles.map((mo) => mo.obstacle_id))
    )
    .flat()
    .flat()
    .filter((x) => x);
  const predefinedObstacles = await fetchPredefinedObstacles(
    postgrest,
    predefinedObstacleIds,
    activeFBO
  );

  const ramps = rampDataRaw.map((rampData) => {
    const activeStack = isReference
      ? rampData.stacks.find((s) => s.name === "reference")
      : rampData.stacks.find((s) => s.name === "current");
    const ramp: Ramp = {
      id: rampData.id,
      location_id: rampData.location_id,
      fbo_id: rampData.fbo_id,
      name: rampData.name,
      version: rampData.version,
      width: rampData.width,
      depth: rampData.depth,
      outline: rampData.outline,
      reference_image: rampData.reference_image,
      markings: rampData.markings ?? [],
      shapes: rampData.shapes ?? [],
      stacks: rampData.stacks.map((stack) => ({
        id: stack.id,
        location_id: stack.location_id,
        name: stack.name,
        snapshot_at: stack.snapshot_at,
        tenants: stack.tenants.map((tenant) => ({
          ...tenant,
          selected: false,
          placement_id: uuidv4(), // -- swap out for position.id
          aircraft: aircrafts.find(({ id }) => tenant.aircraft_id === id) ?? {},
          position: {
            ...tenant.position,
            preferences:
              tenant.position?.preferences ??
              defaultPreferenceForAircraft(
                aircrafts.find(({ id }) => tenant.aircraft_id === id),
                activeFBO
              ),
          },
        })),
        movableObstacles: stack.movable_obstacles.map((movableObstacle) => ({
          ...movableObstacle,
          selected: movableObstacle.selected ?? false,
          placement_id: movableObstacle.position[0],
          obstacle: predefinedObstacles.find(
            (p) => p.id === movableObstacle.obstacle_id
          ),
          position: {
            ...movableObstacle.position,
            preferences: movableObstacle.position?.preferences ?? [],
          },
        })),
        options: {
          // these are the defaults
          wall_spacing_left: 2,
          wall_spacing_back: 2,
          wall_spacing_right: 2,
          horizontal_spacing: activeFBO?.sop_horizontal_spacing,
          vertical_spacing: activeFBO?.sop_vertical_spacing,
          overlap: activeFBO?.sop_overlap,
          tug_spacing: 10,
          density_threshold: 0.8,
          towing_equipment_id: null,
          corner_strategy: [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
          cloud_position_prioritization: true,
          sample_size: 1000,
          xy_weights: [1, 2],
        },
      })),
      // for now we'll just make a new stack each time.
      // this will be our default stack. eventually we'll pull this from the db if it exists
      stack: {
        id: activeStack.id,
        name: activeStack.name,
        location_id: rampData.location_id,
        isReference,
        tenants: activeStack.tenants.map((tenant) => ({
          ...tenant,
          selected: false,
          placement_id: uuidv4(), // -- swap out for position.id
          aircraft: aircrafts.find(({ id }) => tenant.aircraft_id === id) ?? {},
          position: {
            ...tenant.position,
            preferences:
              tenant.position?.preferences ??
              defaultPreferenceForAircraft(
                aircrafts.find(({ id }) => tenant.aircraft_id === id),
                activeFBO
              ),
          },
        })),
        movableObstacles: activeStack.movable_obstacles.map(
          (movableObstacle) => ({
            ...movableObstacle,
            selected: movableObstacle.selected ?? false,
            placement_id: movableObstacle.position[0],
            obstacle: predefinedObstacles.find(
              (p) => p.id === movableObstacle.obstacle_id
            ),
            position: {
              ...movableObstacle.position,
              preferences: movableObstacle.position?.preferences ?? [],
            },
          })
        ),
        options: {
          // these are the defaults
          wall_spacing_left: 2,
          wall_spacing_back: 2,
          wall_spacing_right: 2,
          horizontal_spacing: activeFBO?.sop_horizontal_spacing,
          vertical_spacing: activeFBO?.sop_vertical_spacing,
          overlap: activeFBO?.sop_overlap,
          tug_spacing: 10,
          density_threshold: 0.8,
          towing_equipment_id: null,
          corner_strategy: [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
          cloud_position_prioritization: true,
          sample_size: 1000,
          xy_weights: [1, 2],
        },
      },
    };
    return ramp;
  });
  return ramps;
};

/**
 * This will grab everything you need for a hangar in one feel swoop. Woo hoo! Less queries
 * means way faster on the front end. We'll also use this for individual hangars to keep
 * things consistent.
 *
 * @param postgrest
 * @param activeFBO
 * @param isReference
 * @param limitToHangarIds
 * @returns
 */
export const fetchHangars = async (
  postgrest: PostgrestClient,
  activeFBO: FBO,
  isReference: boolean,
  limitToHangarIds: string[] = []
): Promise<Hangar[]> => {
  let query = postgrest
    .from("hangars")
    .select("*")
    .eq("fbo_id", activeFBO.id)
    .order("name");

  if (limitToHangarIds.length) {
    query = query.in("id", limitToHangarIds);
  }

  const { data: __hangarDataRaw } = await query;
  const hangarDataRaw = __hangarDataRaw.map((h) => h.hangar);

  const aircraftIds = hangarDataRaw
    .map(({ stacks }) =>
      stacks.map((stack) => stack.tenants.map((t) => t.aircraft_id))
    )
    .flat()
    .flat()
    .filter((x) => x);

  const aircrafts = await fetchAircraftWithCache(
    postgrest,
    aircraftIds,
    activeFBO
  );
  const predefinedObstacleIds = hangarDataRaw
    .map(({ stacks }) =>
      stacks.map((stack) => stack.movable_obstacles.map((mo) => mo.obstacle_id))
    )
    .flat()
    .flat()
    .filter((x) => x);
  const predefinedObstacles = await fetchPredefinedObstacles(
    postgrest,
    predefinedObstacleIds,
    activeFBO
  );

  const hangars = hangarDataRaw.map((hangarData) => {
    const activeStack = isReference
      ? hangarData.stacks.find((s) => s.name === "reference")
      : hangarData.stacks.find((s) => s.name === "current");

    const referenceStack = hangarData.stacks.find(
      (s) => s.name === "reference"
    );
    const base_tenant_value =
      sum(
        referenceStack?.tenants?.map((t) => {
          const { wingspan, length } = aircrafts.find(
            ({ id }) => t.aircraft_id === id
          );
          return wingspan * length * activeFBO.nightly_base_rate;
        })
      ) || 0;
    const hangar: Hangar = {
      id: hangarData.id,
      location_id: hangarData.location_id,
      fbo_id: hangarData.fbo_id,
      name: hangarData.name,
      width: hangarData.width,
      depth: hangarData.depth,
      height: hangarData.height,
      left_door: hangarData.left_door,
      right_door: hangarData.right_door,
      wall_spacing_left: hangarData.wall_spacing_left,
      wall_spacing_back: hangarData.wall_spacing_back,
      wall_spacing_right: hangarData.wall_spacing_right,
      last_stacked: hangarData.last_stacked,
      base_tenant_value: base_tenant_value ?? 0,
      autostacking_enabled: hangarData.autostacking_enabled,
      created_at: hangarData.created_at,
      obstacles: hangarData.obstacles.map((obstacle) => {
        const o: Obstacle = {
          id: obstacle.id,
          fbo_id: obstacle.fbo_id,
          hangar_id: hangarData.id,
          x: obstacle.x,
          y: obstacle.y,
          width: obstacle.width,
          depth: obstacle.depth,
          height: obstacle.height,
          geom: obstacle.geom,
          type: obstacle.type,
        };
        return o;
      }),
      garageDoors: hangarData.garage_doors.map((garageDoor) => {
        const g: GarageDoor = {
          id: garageDoor.id,
          fbo_id: garageDoor.fbo_id,
          hangar_id: garageDoor.hangar_id,
          wall: garageDoor.wall,
          x: garageDoor.x,
          y: garageDoor.y,
          width: garageDoor.width,
        };
        return g;
      }),
      stacks: hangarData.stacks.map((stack) => ({
        id: stack.id,
        name: stack.name,
        snapshot_at: stack.snapshot_at,
        location_id: stack.location_id,
        tenants: stack.tenants.map((tenant) => ({
          ...tenant,
          selected: false,
          placement_id: uuidv4(), // -- swap out for position.id
          aircraft: aircrafts.find(({ id }) => tenant.aircraft_id === id) ?? {},
          position: {
            ...tenant.position,
            preferences:
              tenant.position?.preferences ??
              defaultPreferenceForAircraft(
                aircrafts.find(({ id }) => tenant.aircraft_id === id),
                activeFBO
              ),
          },
        })),
        movableObstacles: stack.movable_obstacles.map((movableObstacle) => ({
          ...movableObstacle,
          selected: movableObstacle.selected ?? false,
          placement_id: movableObstacle.position[0],
          obstacle: predefinedObstacles.find(
            (p) => p.id === movableObstacle.obstacle_id
          ),
          position: {
            ...movableObstacle.position,
            preferences: movableObstacle.position?.preferences ?? [],
          },
        })),
        options: {
          // these are the defaults
          wall_spacing_left: hangarData.wall_spacing_left ?? 2,
          wall_spacing_back: hangarData.wall_spacing_back ?? 2,
          wall_spacing_right: hangarData.wall_spacing_right ?? 2,
          horizontal_spacing: activeFBO?.sop_horizontal_spacing,
          vertical_spacing: activeFBO?.sop_vertical_spacing,
          overlap: activeFBO?.sop_overlap,
          tug_spacing: 10,
          density_threshold: 0.8,
          towing_equipment_id: null,
          corner_strategy: [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
          cloud_position_prioritization: true,
          sample_size: 1000,
          xy_weights: [1, 2],
        },
      })),
      // for now we'll just make a new stack each time.
      // this will be our default stack. eventually we'll pull this from the db if it exists
      stack: {
        id: activeStack.id,
        name: activeStack.name,
        location_id: hangarData.location_id,
        isReference,
        tenants: activeStack.tenants.map((tenant) => ({
          ...tenant,
          selected: false,
          placement_id: uuidv4(), // -- swap out for position.id
          aircraft: aircrafts.find(({ id }) => tenant.aircraft_id === id) ?? {},
          position: {
            ...tenant.position,
            preferences:
              tenant.position?.preferences ??
              defaultPreferenceForAircraft(
                aircrafts.find(({ id }) => tenant.aircraft_id === id),
                activeFBO
              ),
          },
        })),
        movableObstacles: activeStack.movable_obstacles.map(
          (movableObstacle) => ({
            ...movableObstacle,
            selected: movableObstacle.selected ?? false,
            placement_id: movableObstacle.position[0],
            obstacle: predefinedObstacles.find(
              (p) => p.id === movableObstacle.obstacle_id
            ),
            position: {
              ...movableObstacle.position,
              preferences: movableObstacle.position?.preferences ?? [],
            },
          })
        ),
        options: {
          // these are the defaults
          wall_spacing_left: hangarData.wall_spacing_left ?? 2,
          wall_spacing_back: hangarData.wall_spacing_back ?? 2,
          wall_spacing_right: hangarData.wall_spacing_right ?? 2,
          horizontal_spacing: activeFBO?.sop_horizontal_spacing,
          vertical_spacing: activeFBO?.sop_vertical_spacing,
          overlap: activeFBO?.sop_overlap,
          tug_spacing: 10,
          density_threshold: 0.8,
          towing_equipment_id: null,
          corner_strategy: [CornerStrategy.ANGLE_45, CornerStrategy.ANGLE_45],
          cloud_position_prioritization: true,
          sample_size: 1000,
          xy_weights: [1, 2],
        },
      },
    };
    return hangar;
  });
  return hangars;
};

export const fetchHeatmap = async (
  postgrest: PostgrestClient,
  hangar_id: string
) => {
  const { data } = await postgrest.rpc("generate_hangar_heatmap", {
    id: hangar_id,
  });
  return data;
};
