import InfoIcon from "@mui/icons-material/Info";
import { Divider, Stack, Switch, Tooltip, Typography } from "@mui/material";
import * as React from "react";
import { useIdentity } from "../../../containers/IdentityContainer";
import { FBOSettings } from "../../../types";

type Props = {
  settings: FBOSettings;
  setSettings: (settings: FBOSettings) => void;
};

export const ConfigurationPresenter: React.FC<Props> = ({
  settings,
  setSettings,
}) => {
  const { airplxIdentity } = useIdentity();
  const permissions = [
    {
      field: "displayEstimatedValue",
      label: "Display Estimated Value",
      description: "Display estimated value on the dashboard.",
      default: true,
    },
    {
      field: "addTeamMembers",
      label: "Add Team Members",
      description: "Add team members to the FBO.",
      default: true,
    },
    {
      field: "modifyTenants",
      label: "Modify Tenants",
      description: "Modify tenant information.",
      default: true,
    },
    {
      field: "useFavorites",
      label: "Use Favorites",
      description:
        "Use the favorites feature to save, load, and edit favorite stacks.",
      default: true,
    },
    {
      field: "viewOnly",
      label: "View Only",
      description:
        "If selected, this allows the user to view & print all hangars and ramps, but all other functionality is disabled.",
      default: false,
    },
    {
      field: "viewEditSchedule",
      label: "View/Edit Schedule",
      description:
        "If turn offed, the user can only see what’s the most recent/active in the hangar for today/currently. So cant scroll through hangar dates/time, etc.",
    },
  ];

  return (
    <Stack
      direction="column"
      spacing={4}
      p={2}
      sx={{ overflowY: "auto", maxHeight: "80vh" }}
    >
      {/* new permissions */}
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Role Permissions</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Toggle permissions for each user role. These permissions will be
          applied to all users in each role.
        </Typography>
        <Stack direction="row" spacing={2} alignItems="center" width={500}>
          {/* Permissions Column */}
          {/* Manager Column */}
          <Stack direction="column" spacing={2}>
            <Typography variant="subtitle1" fontWeight="bold" align="right">
              Manager
            </Typography>
            {permissions.map((permission, index) => (
              <Stack
                key={index}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                width={400}
              >
                <Stack direction="column">
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Tooltip title={permission.description}>
                      <InfoIcon sx={{ color: "info.dark" }} />
                    </Tooltip>
                    <Typography variant="body1">{permission.label}</Typography>
                  </Stack>
                </Stack>
                <Switch
                  key={index}
                  onChange={(evt) => {
                    setSettings({
                      ...settings,
                      [`${permission.field}ForManager`]: evt.target.checked,
                    });
                  }}
                  checked={
                    settings[`${permission.field}ForManager`] ??
                    permission.default
                  }
                />
              </Stack>
            ))}
          </Stack>
          <Divider orientation="vertical" flexItem />
          {/* Operator Column */}
          <Stack direction="column" spacing={2} alignItems="center">
            <Typography variant="subtitle1" fontWeight="bold">
              Operator
            </Typography>
            {permissions.map((permission, index) => (
              <Switch
                key={index}
                onChange={(evt) => {
                  setSettings({
                    ...settings,
                    [`${permission.field}ForOperator`]: evt.target.checked,
                  });
                }}
                checked={
                  settings[`${permission.field}ForOperator`] ??
                  permission.default
                }
              />
            ))}
          </Stack>
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Global Permissions</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Toggle global permissions. These will be applied to all users,
          regardless of role.
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="body1">
              Exclude obstructions, safety perimeters, and no-tow areas?
            </Typography>
            <Tooltip
              title={`By default, AirPlx uses the hangar width & depth as total hangar square footage for utilization calcs. You can exclude obstructions (offices, cages, etc) and safety perimeters (no-tow areas) if desired. `}
            >
              <InfoIcon sx={{ color: "info.dark" }} />
            </Tooltip>
          </Stack>

          <Switch
            checked={settings?.ignoreDeadSpaceForUtilization ?? false}
            onChange={(evt) =>
              setSettings({
                ...settings,
                ignoreDeadSpaceForUtilization: evt.target.checked,
              })
            }
          />
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography>
            Notes in "View Settings" shows all entered note text
          </Typography>
          <Switch
            checked={settings?.forceDisplayNotes ?? false}
            onChange={(evt) =>
              setSettings({
                ...settings,
                forceDisplayNotes: evt.target.checked,
              })
            }
          />
        </Stack>

        {airplxIdentity.isadmin && (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Typography>Use Metric System</Typography>
            <Switch
              checked={settings?.useMetricSystem ?? false}
              onChange={(evt) =>
                setSettings({
                  ...settings,
                  useMetricSystem: evt.target.checked,
                })
              }
            />
          </Stack>
        )}
      </Stack>
      <Divider />
      <Stack direction="column" spacing={1}>
        <Typography variant="subtitle1">Schedule Settings</Typography>
        <Typography variant="subtitle2" color="textSecondary">
          Toggle schedule settings.
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography>FBO Date & Time</Typography>
          <Typography color="textSecondary">
            TODO: Implement. Not sure what this does.
          </Typography>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography>Utilization Thresholds</Typography>
          <Typography color="textSecondary">
            TODO: Implement. Not sure what this does.
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
