import BeachAccessIcon from "@mui/icons-material/BeachAccess";
import FlightIcon from "@mui/icons-material/Flight";
import LockIcon from "@mui/icons-material/Lock";
import SwitchLeftIcon from "@mui/icons-material/SwitchLeft";
import { Box, Chip, Stack, Typography, useTheme } from "@mui/material";
import * as React from "react";
import { useRampState } from "../../containers/RampStateContainer";
import { Preference, Stack as StackType, Tenant } from "../../types";
import { AircraftPreferencesListItemMenuButton } from "./AircraftPreferencesListItemMenu";
import { AircraftSpecsTooltip } from "./AircraftSpecsTooltip";
import { NoteTooltip } from "./NoteTooltip";
import { TripList } from "./TripList";

const PlacementSetting = ({ icon, text }) => (
  <Stack
    key={`PlacementSetting-${text.replace(/ /g, "-")}`}
    direction="row"
    spacing={1}
    alignItems="center"
  >
    {icon}
    <Typography variant="caption">{text}</Typography>
  </Stack>
);

const ORDERED_PREFERENCES = [
  Preference.TAIL_IN,
  Preference.NOSE_IN,
  Preference.PREFER_FRONT_ROW,
  Preference.PREFER_BACK_ROW,
  Preference.OK_TO_LEAVE_OUT,
  Preference.LOCKED,
  Preference.TAIL_WHEEL_NOSE_IN,
  Preference.TAIL_WHEEL_TAIL_IN,
];

type AircraftListItemPresenterProps = {
  stack: StackType;
  setStack: (stack: StackType) => void;
  tenant: Tenant;
  setTenant: (tenant: Tenant) => void;
  onSelectAircraft: () => void;
  onDeleteAircraft: () => void;
  showBottomBorder?: boolean;
  schedule?: boolean;
};

export const AircraftListItemPresenter: React.FC<AircraftListItemPresenterProps> = ({
  stack,
  setStack,
  tenant,
  setTenant,
  onSelectAircraft,
  onDeleteAircraft,
  showBottomBorder = false,
  schedule = false,
}) => {
  const { ramp } = useRampState();
  const isRamp = Boolean(ramp);
  const theme = useTheme();
  return (
    <Stack
      className={tenant.selected && "selected"}
      data-element="li_current_aircraft"
      spacing={1}
      sx={{
        borderTop: "1px solid lightgrey",
        borderBottom: showBottomBorder ? null : "none",
        backgroundColor: tenant.selected && `${theme.palette.success.main}15`,
        p: 1.5,
        cursor: "pointer",
      }}
      onClick={onSelectAircraft}
    >
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" spacing={1} alignItems="center">
          {Boolean(tenant.tail_number) && (
            <Chip size="small" label={tenant.tail_number} />
          )}
          {tenant.type === "base" ? (
            <Chip size="small" variant="outlined" label="Tenant" />
          ) : (
            <Chip size="small" variant="outlined" label="Transient" />
          )}
        </Stack>
        <Box onClick={(evt) => evt.stopPropagation()}>
          <AircraftPreferencesListItemMenuButton
            tenant={tenant}
            stack={stack}
            setStack={setStack}
            setTenant={setTenant}
            preferences={tenant.position.preferences}
            setPreferences={(preferences) => {
              setTenant({
                ...tenant,
                position: {
                  ...tenant.position,
                  preferences,
                },
              });
            }}
            onDeleteAircraft={onDeleteAircraft}
          />
        </Box>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography>{tenant.aircraft.model}</Typography>
        <AircraftSpecsTooltip aircraft={tenant.aircraft} />
        <NoteTooltip note={tenant.note} />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="start"
        spacing={2}
      >
        {isRamp
          ? []
          : ORDERED_PREFERENCES.filter(
              (p) => tenant.position.preferences.indexOf(p) > -1
            ).map((setting) => {
              let content = null;

              if (setting === Preference.NOSE_IN) {
                content = (
                  <PlacementSetting
                    icon={<FlightIcon fontSize="small" />}
                    text="Nose In"
                  />
                );
              }
              if (setting === Preference.TAIL_IN) {
                content = (
                  <PlacementSetting
                    icon={
                      <FlightIcon
                        fontSize="small"
                        sx={{ transform: "rotate(180deg)" }}
                      />
                    }
                    text="Tail In"
                  />
                );
              }
              if (setting === Preference.TAIL_WHEEL_NOSE_IN) {
                content = (
                  <PlacementSetting
                    icon={<FlightIcon fontSize="small" />}
                    text="Nose In"
                  />
                );
              }

              if (setting === Preference.TAIL_WHEEL_TAIL_IN) {
                content = (
                  <PlacementSetting
                    icon={
                      <FlightIcon
                        fontSize="small"
                        sx={{ transform: "rotate(180deg)" }}
                      />
                    }
                    text="Tail In"
                  />
                );
              }

              if (setting === Preference.PREFER_FRONT_ROW) {
                content = (
                  <PlacementSetting
                    icon={
                      <SwitchLeftIcon
                        fontSize="small"
                        sx={{ transform: "rotate(90deg)" }}
                      />
                    }
                    text="Front"
                  />
                );
              }
              if (setting === Preference.PREFER_BACK_ROW) {
                content = (
                  <PlacementSetting
                    icon={
                      <SwitchLeftIcon
                        fontSize="small"
                        sx={{ transform: "rotate(-90deg)" }}
                      />
                    }
                    text="Back"
                  />
                );
              }
              if (setting === Preference.OK_TO_LEAVE_OUT) {
                content = (
                  <PlacementSetting
                    icon={<BeachAccessIcon fontSize="small" />}
                    text="OK Outside"
                  />
                );
              }
              if (setting === Preference.LOCKED) {
                content = (
                  <PlacementSetting
                    icon={<LockIcon fontSize="small" />}
                    text="Locked"
                  />
                );
              }
              return (
                <React.Fragment key={`setting-${setting}`}>
                  {content}
                </React.Fragment>
              );
            })}
      </Stack>
      {tenant.selected && schedule && (
        <Box>
          <TripList
            tenant={tenant}
            onDateChange={(x) => {
              console.log(x);
            }}
          />
        </Box>
      )}
    </Stack>
  );
};

type Props = {
  stack: StackType;
  setStack: (stack: StackType) => void;
  showBottomBorder?: boolean;
  setTenant: (t: Tenant) => void;
  onSelectAircraft: (t: Tenant) => void;
  schedule?: boolean;
  tenant: Tenant;
  onDeleteAircraft: (t: Tenant) => void;
};

export const AircraftListItem: React.FC<Props> = ({
  stack,
  setStack,
  tenant,
  showBottomBorder,
  setTenant,
  onSelectAircraft,
  onDeleteAircraft,
  schedule = false,
}) => {
  return (
    <AircraftListItemPresenter
      schedule={schedule}
      tenant={tenant}
      stack={stack}
      setStack={setStack}
      setTenant={setTenant}
      onSelectAircraft={() => onSelectAircraft(tenant)}
      onDeleteAircraft={() => onDeleteAircraft(tenant)}
      showBottomBorder={showBottomBorder}
    />
  );
};
