import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { format as formatDate } from "date-fns";
import { sum } from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useHangarsState } from "../../containers/HangarsStateContainer";
import { useIdentity } from "../../containers/IdentityContainer";
import { getStackPolygons } from "../../hooks/utils";
import { FBO, Hangar, Preference, Tenant } from "../../types";
import { formatCurrency } from "../../utils";
import mixpanel from "../../utils/mixpanel";
import { Loading } from "../../widgets/Loading";
import {
  calculateTotalArea,
  calculateUtilization,
  findTenantsFullyInHangar,
} from "../Hangar/HangarInfoCard";
import { MultiHangarButton } from "./MultiHangarSelector";

export const getNumberFromFirstOrLastWord = (
  text: string
): [number, string] => {
  // get first integer from text via regex
  const matches = text.match(/\d+/);
  if (matches) {
    return [Number(matches[0]), text.match(/\d+[A-Za-z]+/)?.[0]];
  }
  return [null, null];
};

export const sortHangars = (
  a: Hangar,
  b: Hangar,
  hangarDisplayOrder?: string[]
): number => {
  if (hangarDisplayOrder) {
    const aIndex = hangarDisplayOrder.indexOf(a.id);
    const bIndex = hangarDisplayOrder.indexOf(b.id);
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    } else if (aIndex !== -1) {
      return -1;
    } else if (bIndex !== -1) {
      return 1;
    }
  }
  return sortByNumberThenText(a.name, b.name);
};

export const sortByNumberThenText = (a: string, b: string): number => {
  const [aNumber, aAlphaNumeric] = getNumberFromFirstOrLastWord(a);
  const [bNumber, bAlphaNumeric] = getNumberFromFirstOrLastWord(b);

  if (aNumber !== null && bNumber !== null) {
    // If both first words are numbers, compare them numerically
    if (Number(aNumber) === Number(bNumber)) {
      if (aAlphaNumeric && bAlphaNumeric) {
        return aAlphaNumeric.localeCompare(bAlphaNumeric);
      }
      return a.localeCompare(b);
    }
    return Number(aNumber) - Number(bNumber);
  } else if (aNumber !== null) {
    // If only the first word in a is a number, a comes before b
    return -1;
  } else if (bNumber !== null) {
    // If only the first word in b is a number, b comes before a
    return 1;
  } else {
    // Otherwise, compare alphabetically
    return a.localeCompare(b);
  }
};

type Props = {};

export const estimateValue = (
  fbo: FBO,
  hangar: Hangar,
  polygons: any,
  ignoreDeadSpaceForUtilization: boolean = false,
  monthly: boolean = false
): string => {
  if (hangar.stack.name === "reference") {
    const tenantUtilization = calculateUtilization(
      hangar,
      polygons,
      false,
      false // ignoreDeadSpaceForUtilization
    );
    const sqftValue = fbo.nightly_base_rate ?? 0.2;
    const value =
      (tenantUtilization / 100) *
      hangar.width *
      hangar.depth *
      sqftValue *
      (monthly ? 30.44 : 1);
    return formatCurrency(10 * Math.round(Math.ceil(value) / 10), 0);
  }
  const transientUtilization = calculateUtilization(
    {
      ...hangar,
      stack: {
        ...hangar.stack,
        tenants: hangar.stack.tenants.filter((t) => t.type === "transient"),
      },
    },
    polygons,
    true,
    false // ignoreDeadSpaceForUtilization
  );
  const sqftValue = fbo.nightly_transient_rate ?? 0.2;
  const value =
    (monthly ? 30.44 : 1) * hangar.base_tenant_value +
    (transientUtilization / 100) *
      hangar.width *
      hangar.depth *
      sqftValue *
      (monthly ? 30.44 : 1);
  return formatCurrency(10 * Math.round(Math.ceil(value) / 10), 0);
};

const isLocked = (t: Tenant): boolean => {
  return (
    t.position.preferences.find((p) => p === Preference.LOCKED) !== undefined
  );
};

export const calcEstimateTowTime = (hangar, polygons: any): string => {
  const tenants = findTenantsFullyInHangar(hangar, polygons).filter(
    (t) => !isLocked(t)
  );
  const dist =
    sum(
      tenants.map((t) =>
        Math.max(
          0,
          Math.abs(t.position.angle) > 90
            ? t.position.y ?? 0
            : t.aircraft.length + (t.position.y ?? 0)
        )
      )
    ) ?? 0;
  const totalMinutes = (2 * dist) / 150 + 6 * tenants.length;
  if (!totalMinutes) {
    return "–––";
  }
  return `${Math.ceil(totalMinutes)} min`;
};

export const HangarsPresenter: React.FC<Props> = ({ ...props }) => {
  const { airplxIdentity } = useIdentity();
  const { activeFBO } = useActiveFBOs();
  const isPeterOrGreg = airplxIdentity.isadmin;
  const displayEstimatedValue =
    activeFBO.user_role === "manager"
      ? activeFBO?.settings?.displayEstimatedValueForManager ?? true
      : activeFBO?.settings?.displayEstimatedValueForOperator ?? true;

  const displayEditTenants =
    (activeFBO.user_role === "manager" &&
      activeFBO?.settings?.modifyTenantsForManager) ||
    (activeFBO?.user_role === "operator" &&
      activeFBO?.settings?.modifyTenantsForOperator) ||
    airplxIdentity.isadmin;

  const { ready, hangars } = useHangarsState();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Hangar Name",
      width: 200,
      editable: false,
      sortable: false,
    },
    {
      field: "tenants",
      headerName: "Current Aircraft",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="inherit">Aircraft</Typography>
          <Tooltip title="# aircraft inside hangar / # aircraft wanted inside">
            <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
          </Tooltip>
        </Stack>
      ),
      width: 155,
      editable: false,
      sortable: false,
      valueGetter: (value: any, row) => {
        const nTenantsFullyInHangar = findTenantsFullyInHangar(
          row,
          getStackPolygons(row.stack, row.width, 1)
        ).length;
        return `${nTenantsFullyInHangar} / ${row.stack.tenants.length || 0}`;
      },
    },
    {
      field: "base_tenants_utilization",
      headerName: "Current Utilization",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="inherit">Utilization</Typography>
          <Tooltip title="Stacked aircraft utl. / All aircraft on screen utl. You can adjust how utilization is calculated in Settings > Configuration.">
            <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
          </Tooltip>
        </Stack>
      ),
      width: 175,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        const totalUtilization = sum(
          row.stack.tenants.map(
            ({ aircraft }) => aircraft.wingspan * aircraft.length
          )
        );
        const totalUtilizationPercent = Math.round(
          (100 * totalUtilization) /
            calculateTotalArea(
              row,
              activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
            )
        );

        return `${calculateUtilization(
          row,
          getStackPolygons(row.stack, row.width, 1),
          true,
          activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
        )}% / ${totalUtilizationPercent}%`;
      },
    },
    {
      field: "estimated_hangar_value",
      headerName: "Est. Value",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="inherit">Est. Value</Typography>
          <Tooltip title="Estimated value of the hangar per night based on aircraft fully inside the hangar">
            <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
          </Tooltip>
        </Stack>
      ),
      width: 115,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        return estimateValue(
          activeFBO,
          row,
          getStackPolygons(row.stack, row.width, 1),
          activeFBO?.settings?.ignoreDeadSpaceForUtilization ?? false
        );
      },
    },
    {
      field: "estimated_tow_time",
      headerName: "Est. Tow Time",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="inherit">Est. Tow Time</Typography>
          <Tooltip title="Estimated time to tow hangar into position">
            <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
          </Tooltip>
        </Stack>
      ),
      width: 140,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        return calcEstimateTowTime(
          row,
          getStackPolygons(row.stack, row.width, 1)
        );
      },
    },
    {
      field: "last_stacked",
      headerName: "Last Stacked",
      width: 125,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        if (!row.last_stacked) {
          return "Never Stacked";
        }
        return formatDate(new Date(row.last_stacked), "MMM dd, yyyy");
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: isPeterOrGreg ? 350 : 250,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => {
                mixpanel.track("Clicked Hangar", {
                  location_name: params.row.name,
                  location_type: "hangar",
                });
                navigate(`/hangars/${params.row.id}`);
              }}
            >
              Scratch
            </Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                mixpanel.track("Clicked Hangar", {
                  location_name: params.row.name,
                  location_type: "hangar",
                });
                navigate(`/hangars/${params.row.id}/schedule`);
              }}
            >
              Scheduled
            </Button>
            {displayEditTenants && (
              <Button
                size="small"
                variant="contained"
                sx={{ backgroundColor: "#f5a89d" }}
                onClick={() => {
                  navigate(`/reference-hangars/${params.row.id}`);
                }}
              >
                Edit Tenants
              </Button>
            )}
            {/* {!useFeatureFlags().isProduction && isPeterOrGreg && (
              <Button
                size="small"
                variant="contained"
                color="secondary"
                startIcon={<SpeedIcon />}
                onClick={() => {
                  navigate(`/algorithm-evaluation/${params.row.id}`);
                }}
              >
                Algo Testing
              </Button>
            )} */}
          </Stack>
        );
      },
    },
  ];

  const columnVisibilityModel = {
    // name of column field: true/false
    estimated_hangar_value: displayEstimatedValue,
  };

  return (
    <Box sx={{ ml: 2, mr: 2, height: "100%" }}>
      <Stack direction="column" sx={{ height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 2, mb: 2 }}
        >
          <Typography variant="h6">Hangars – {activeFBO?.name}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ mb: 2 }}
        >
          <Stack
            direction="row"
            spacing={2}
            justifyContent="space-between"
            sx={{ minWidth: 500 }}
          >
            <Card variant="outlined" sx={{ width: "100%" }}>
              <CardContent>
                <Typography variant="subtitle1">Total Hangars</Typography>
                <Typography variant="h5" data-testid="total_hangars">
                  {hangars.length}
                </Typography>
              </CardContent>
            </Card>
            <Card variant="outlined" sx={{ width: "100%" }}>
              <CardContent>
                <Typography variant="subtitle1">
                  Total Aircraft in Hangars
                </Typography>
                <Typography variant="h5" data-testid="total_aircraft">
                  {sum(hangars.map((h) => h.stack.tenants.length))}
                </Typography>
              </CardContent>
            </Card>
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-end"
            alignItems="flex-end"
            sx={{ width: 200, height: "100%" }}
          >
            <MultiHangarButton hangars={hangars} />
          </Stack>
        </Stack>
        {!ready ? (
          <Loading />
        ) : (
          <DataGrid
            rows={[...hangars].sort((a, b) =>
              sortHangars(a, b, activeFBO?.settings?.hangarDisplayOrder)
            )}
            columns={columns}
            pageSizeOptions={[100]}
            disableRowSelectionOnClick
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            columnVisibilityModel={columnVisibilityModel}
          />
        )}
      </Stack>
    </Box>
  );
};
