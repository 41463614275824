import { pick } from "lodash";
import * as md5 from "md5";
import * as React from "react";
import { usePrevious } from "react-use";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import {
  GarageDoor,
  Hangar,
  MovableObstacle,
  Obstacle,
  Position,
  Ramp,
  Stack,
  Tenant,
  Trip,
} from "../types";
import { fetchHangar } from "./api";
import { useStateHistory } from "./useMeaningfulStateHistory";
import useSyncToTable from "./useSyncToTable";

export const extractImportantLocationState = (location: Hangar | Ramp) => {
  if (!location?.stack) {
    return null;
  }
  const state = location.stack.tenants
    .sort((a, b) => a.id.localeCompare(b.id))
    .map((t) => ({
      position: pick(t.position, ["x", "y", "angle"]),
    }));
  return state;
};

// keep track of the hangar state history. i can't just use useStateHistory because i also need
// to filter out "unworthy" state events.
export const isLocationChangeMeaningful = (
  oldState: Hangar | Ramp,
  newState: Hangar | Ramp
): boolean => {
  const oldStateHash = md5(
    JSON.stringify(extractImportantLocationState(oldState))
  );
  const newStateHash = md5(
    JSON.stringify(extractImportantLocationState(newState))
  );
  return oldStateHash !== newStateHash;
};

export const useHangar = (hangar_id: string, isReference: boolean) => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [ready, setReady] = React.useState<boolean>(false);
  // const [hangar, setHangar] = React.useState<Hangar>(null);
  const [hangar, setHangar, undo, redo] = useStateHistory<Hangar>(null);
  // 'stack' is just for convenience. the data that gets synced is the stack[] since it's now a table.
  React.useEffect(() => {
    if (!hangar?.stack) {
      return;
    }
    setHangar(
      {
        ...hangar,
        stacks: hangar?.stacks?.map((stack) =>
          stack.id === hangar.stack.id ? hangar.stack : stack
        ),
      },
      isLocationChangeMeaningful
    );
  }, [md5(JSON.stringify(hangar?.stack) ?? "")]);

  const previousHangar = usePrevious(hangar);

  useSyncToTable<Hangar>({
    thingToWatch: hangar,
    tableName: "hangar",
  });

  const stacks = hangar?.stacks ?? [];
  useSyncToTable<Stack[]>({
    tableName: "stack",
    thingToWatch: Boolean(hangar) ? stacks : null,
  });

  useSyncToTable<Tenant[]>({
    // pass in null until we actually have data
    thingToWatch: Boolean(hangar) ? hangar?.stack?.tenants : null,
    tableName: "tenant",
    deleteFromDatabase: async (id: string) => {
      // we don't actually delete tenants. they just leave the hangar. we're still
      // tracking them in this file b/c we need to be able to edit their info from
      // the hangar.
    },
  });

  useSyncToTable<MovableObstacle[]>({
    thingToWatch: Boolean(hangar) ? hangar?.stack?.movableObstacles : null,
    tableName: "movable_obstacle",
    deleteFromDatabase: async (id: string) => {
      // we don't actually delete movable obstacles. they just leave the hangar. we're still
      // tracking them in this file b/c we need to be able to edit their info from
      // the hangar.
    },
  });

  const currentPositions: Position[] = [];
  for (const stack of hangar?.stacks ?? []) {
    for (const tenant of stack.tenants ?? []) {
      currentPositions.push(tenant.position);
    }
    for (const movableObstacle of stack.movableObstacles ?? []) {
      currentPositions.push(movableObstacle.position);
    }
  }

  useSyncToTable<Position[]>({
    // pass in null until we actually have data
    thingToWatch: Boolean(hangar) ? currentPositions : null,
    tableName: "position",
    debounce: 500,
  });

  useSyncToTable<Obstacle[]>({
    thingToWatch: Boolean(hangar) ? hangar?.obstacles : null,
    tableName: "obstacle",
  });

  useSyncToTable<GarageDoor[]>({
    thingToWatch: Boolean(hangar) ? hangar?.garageDoors : null,
    tableName: "garage_door",
  });

  const trips: Trip[] = [];
  for (const stack of hangar?.stacks ?? []) {
    for (const tenant of stack.tenants ?? []) {
      for (const trip of tenant.trips ?? []) {
        trips.push(trip);
      }
    }
  }

  useSyncToTable<Trip[]>({
    thingToWatch: Boolean(hangar) ? trips : null,
    tableName: "trip",
  });

  // ok let's grab the hangar! we only do this once. changes made in memory will be
  // synced to the database via the useSyncToTable hook
  React.useEffect(() => {
    (async () => {
      const zHangar = await fetchHangar(
        postgrest,
        activeFBO,
        hangar_id,
        isReference
      );
      setHangar(zHangar, isLocationChangeMeaningful);
      setReady(true);
    })();
  }, []);

  return {
    ready: ready && Boolean(hangar),
    hangar,
    setHangar: (newHangar: Hangar) => {
      setHangar(newHangar, isLocationChangeMeaningful);
    },
    history: {
      undo: (current: Hangar, next: Hangar) => {
        return {
          ...current,
          stack: {
            ...current.stack,
            // replace the positions of the current with the positions of the next
            tenants: current.stack.tenants.map((t) => {
              const nextTenant = next.stack.tenants.find(
                (nt) => nt.id === t.id
              );
              if (!nextTenant) {
                return t;
              }
              return {
                ...t,
                position: nextTenant.position,
              };
            }),
            movableObstacles: current.stack.movableObstacles.map((mo) => {
              const nextMovableObstacle = next.stack.movableObstacles.find(
                (nmo) => nmo.id === mo.id
              );
              if (!nextMovableObstacle) {
                return mo;
              }
              return {
                ...mo,
                position: nextMovableObstacle.position,
              };
            }),
          },
        };
      },
      redo,
    },
    isReference,
  };
};
