import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { DateSection } from "../../screens/Settings/HangarSettings/AddTenantButton/NewAircraftForm";
import { Tenant, Trip } from "../../types";

interface TripRowProps {
  trip: Trip;
  onDateChange: (field: keyof Trip, value: Date) => void;
}

const TripRow: React.FC<TripRowProps> = ({ trip, onDateChange }) => {
  const handleDateChange = (field: keyof Trip) => (newDate: Date) => {
    onDateChange(field, newDate);
  };

  return (
    <Stack direction="column" spacing={1}>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={40}>In</Typography>
        <DateSection
          date={new Date(trip.arrival)}
          onChange={function(date: Date): void {
            throw new Error("Function not implemented.");
          }}
          label={""}
        />
        <IconButton size="small">
          <DeleteIcon />
        </IconButton>
      </Stack>
      <Stack direction="row" spacing={1} alignItems="center">
        <Typography width={40}>Out</Typography>
        <DateSection
          date={new Date(trip.departure)}
          onChange={function(date: Date): void {
            throw new Error("Function not implemented.");
          }}
          label={""}
        />
        <IconButton size="small">
          <DeleteIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

type TripListProps = {
  tenant: Tenant;
  onDateChange: (tripId: string, field: keyof Trip, value: Date) => void;
};

export const TripList: React.FC<TripListProps> = ({ tenant, onDateChange }) => {
  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="column"
        spacing={2}
        pl={2}
        sx={{
          borderLeftStyle: "solid",
          borderLeftWidth: 2,
          borderLeftColor: "success.main",
        }}
      >
        {tenant.trips
          ?.slice()
          .sort((a, b) => {
            const arrivalComparison =
              new Date(a.arrival).getTime() - new Date(b.arrival).getTime();
            if (arrivalComparison !== 0) return arrivalComparison;
            return (
              new Date(a.departure).getTime() - new Date(b.departure).getTime()
            );
          })
          .map((trip) => (
            <Stack key={trip.id} direction="column" spacing={1}>
              <TripRow
                key={trip.id}
                trip={trip}
                onDateChange={(field, value) =>
                  onDateChange(trip.id, field, value)
                }
              />
              <Divider />
            </Stack>
          ))}
      </Stack>

      <Box sx={{ textAlign: "center" }}>
        <Button fullWidth variant="contained">
          Add Trip
        </Button>
      </Box>
    </Stack>
  );
};
