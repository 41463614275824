import InfoIcon from "@mui/icons-material/Info";
import {
  Box,
  Button,
  Card,
  CardContent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridColumnHeaderParams } from "@mui/x-data-grid";
import { sum } from "lodash";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { useRampsState } from "../../containers/RampsStateContainer";
import { Ramp } from "../../types";
import mixpanel from "../../utils/mixpanel";
import { sortByNumberThenText } from "../Hangars/HangarsPresenter";

export const sortRamps = (
  a: Ramp,
  b: Ramp,
  rampDisplayOrder?: string[]
): number => {
  if (rampDisplayOrder) {
    const aIndex = rampDisplayOrder.indexOf(a.id);
    const bIndex = rampDisplayOrder.indexOf(b.id);
    if (aIndex !== -1 && bIndex !== -1) {
      return aIndex - bIndex;
    } else if (aIndex !== -1) {
      return -1;
    } else if (bIndex !== -1) {
      return 1;
    }
  }
  return sortByNumberThenText(a.name, b.name);
};

type Props = {};

export const RampsPresenter: React.FC<Props> = ({ ...props }) => {
  const { activeFBO } = useActiveFBOs();

  const { ramps } = useRampsState();
  const navigate = useNavigate();
  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Ramp Name",
      width: 250,
      editable: false,
      sortable: false,
    },
    {
      field: "tenants",
      headerName: "Current Aircraft",
      renderHeader: (params: GridColumnHeaderParams) => (
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="inherit">Current Aircraft</Typography>
          <Tooltip title="# of aircraft on the ramp">
            <InfoIcon sx={{ color: "info.dark" }} fontSize="small" />
          </Tooltip>
        </Stack>
      ),
      width: 155,
      editable: false,
      sortable: false,
      valueGetter: (_, row) => {
        return row.stack?.tenants?.length ?? "0";
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Button
              size="small"
              variant="contained"
              color="success"
              onClick={() => {
                mixpanel.track("Clicked Ramp", {
                  location_name: params.row.name,
                  location_type: "ramp",
                });
                navigate(`/ramps/${params.row.id}`);
              }}
            >
              Scratch
            </Button>
            <Button
              size="small"
              variant="contained"
              color="secondary"
              onClick={() => {
                mixpanel.track("Clicked Ramp", {
                  location_name: params.row.name,
                  location_type: "ramp",
                });
                navigate(`/ramps/${params.row.id}/schedule`);
              }}
            >
              Schedule
            </Button>
            <Button
              size="small"
              variant="contained"
              color="info"
              onClick={() => {
                navigate(`/reference-ramps/${params.row.id}`);
              }}
            >
              Tie Down Tenants
            </Button>
          </Stack>
        );
      },
    },
  ];

  return (
    <Box sx={{ ml: 2, mr: 2, height: "100%" }}>
      <Stack direction="column" sx={{ height: "100%" }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ mt: 2, mb: 2 }}
        >
          <Typography variant="h6">Ramps – {activeFBO?.name}</Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          sx={{ mb: 2 }}
        >
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="subtitle1">Total Ramps</Typography>
              <Typography variant="h5">{ramps.length}</Typography>
            </CardContent>
          </Card>
          <Card variant="outlined" sx={{ width: "100%" }}>
            <CardContent>
              <Typography variant="subtitle1">
                Total Aircraft on Ramps
              </Typography>
              <Typography variant="h5">{sum(ramps.map((r) => 0))}</Typography>
            </CardContent>
          </Card>
        </Stack>

        <DataGrid
          rows={[...ramps].sort((a, b) =>
            sortRamps(a, b, activeFBO?.settings?.rampDisplayOrder)
          )}
          columns={columns}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
        />
      </Stack>
    </Box>
  );
};
