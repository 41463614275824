import { uniq } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { Tenant, Trip } from "../types";
import { defaultPreferenceForAircraft, uuidv4 } from "../utils";
import { fetchAircraftWithCache } from "./api";
import useSyncToTable from "./useSyncToTable";

export const useTenants = () => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [tenants, setTenants] = React.useState<Tenant[]>();

  const fetchTenantsForFbo = async () => {
    const { data: tenantsRaw } = await postgrest
      .from("tenant_with_trips")
      .select()
      .eq("fbo_id", activeFBO.id)
      .order("last_hangared", { ascending: false, nullsFirst: false });

    const aircraftIds = uniq(tenantsRaw.map(({ aircraft_id }) => aircraft_id));
    const aircrafts = await fetchAircraftWithCache(
      postgrest,
      aircraftIds,
      activeFBO
    );

    const tenants: Tenant[] = tenantsRaw.map((tenant) => ({
      ...tenant,
      id: tenant.id,
      placement_id: uuidv4(),
      aircraft: aircrafts.find(({ id }) => tenant.aircraft_id === id) ?? {},
      selected: false,
      preferences:
        tenant.preferences ??
        defaultPreferenceForAircraft(
          aircrafts.find(({ id }) => tenant.aircraft_id === id),
          activeFBO
        ),
      position: {
        x: null,
        y: null,
        angle: null,
      },
    }));

    setTenants(tenants);
  };

  // grab the hangar to start
  React.useEffect(() => {
    fetchTenantsForFbo();
  }, []);

  const setTenant = React.useCallback(
    (tenant: Tenant) => {
      // if we're updating an existing tenant, then mutate the existing tenants
      if (tenants.find((t) => t.id === tenant.id)) {
        setTenants(tenants.map((t) => (t.id === tenant.id ? tenant : t)));
      } else {
        // otherwise it's a new tenant, so add it
        setTenants([tenant].concat(tenants));
      }
    },
    [tenants]
  );

  useSyncToTable<Tenant[]>({
    thingToWatch: tenants,
    tableName: "tenant",
  });

  const trips: Trip[] = [];
  for (const tenant of tenants ?? []) {
    for (const trip of tenant.trips ?? []) {
      trips.push(trip);
    }
  }

  useSyncToTable<Trip[]>({
    thingToWatch: trips,
    tableName: "trip",
  });

  return {
    tenants: tenants ?? [],
    setTenant,
    setTenants,
  };
};
