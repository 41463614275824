import { MultiPolygon } from "geojson";
import * as geometric from "geometric";
import * as md5 from "md5";
import { wrapUnplacedAirplanes } from "../screens/Hangar/HangarLayout/utils";
import { EntityPolygon, Position, Stack, Tenant } from "../types";

export const positionMultiPolygon = (
  geomz: MultiPolygon,
  position: Position
): MultiPolygon => {
  const updatedCoordinates = geomz.coordinates.map((coords) => {
    return coords.map((c) =>
      c.map((p) => [
        p[0] + position.x,
        p[1] + position.y,
        p[2], // z
        p[3], // m
      ])
    );
  });
  return {
    ...geomz,
    coordinates: updatedCoordinates,
  };
};

export const rotateMultiPolygon = (
  geomz: MultiPolygon,
  pivotPoint: geometric.Point,
  position: Position
): MultiPolygon => {
  const updatedCoordinates = geomz.coordinates.map((coords) => {
    return coords.map((subpolygon) =>
      geometric
        .polygonRotate(
          subpolygon.map((c) => [c[0], c[1]]),
          // geometric rotates the other way, so negate the angle
          -position.angle,
          pivotPoint
        )
        .map((c) => [c[0], c[1], subpolygon[0][2], subpolygon[0][3]])
    );
  });
  return {
    ...geomz,
    coordinates: updatedCoordinates,
  };
};

export const getStackPolygons = (
  stack: Stack,
  layoutWidth: number,
  feetToPixels: number,
  isMultiHangar: boolean = false,
  isHoldingArea: boolean = false
): EntityPolygon[] => {
  if (!stack) {
    return [];
  }
  const tenantPolygons = wrapUnplacedAirplanes(
    stack.tenants,
    layoutWidth,
    feetToPixels,
    isMultiHangar,
    isHoldingArea
  ).map(({ entity_id, position, aircraft }) => {
    /**
     * take the scaled polygon, get it into position, then rotate it on the correct pivot
     * point. the result should be a polygon that matches the outline of each aircraft.
     * we can use this to detect things like collisions
     */
    const basePolygon: geometric.Polygon = aircraft.scaled_geom_full.coordinates[0].map(
      ([x, y]) => [x + position.x, y + position.y]
    );

    const pivotPoint: geometric.Point = [
      aircraft.pivot_point.coordinates[0] + position.x,
      aircraft.pivot_point.coordinates[1] + position.y,
    ];
    const polygon = geometric.polygonRotate(
      basePolygon,
      // geometric rotates the other way, so negate the angle
      -position.angle,
      pivotPoint
    );

    return {
      entity_id,
      polygon,
      hash: md5(JSON.stringify(polygon)),
      bounds: geometric.polygonBounds(polygon),
    };
  });
  const movableObstaclePolygons = stack.movableObstacles.map(
    ({ entity_id, position, obstacle }) => {
      const basePolygon: geometric.Polygon = obstacle.geom.coordinates[0].map(
        ([x, y]) => [x + position.x, y + position.y]
      );
      const center = Math.max(obstacle.width, obstacle.length) / 2;
      const pivotPoint: geometric.Point = [
        center + position.x,
        center + position.y,
      ];
      const polygon = geometric.polygonRotate(
        basePolygon,
        // geometric rotates the other way, so negate the angle
        -position.angle,
        pivotPoint
      );
      return {
        entity_id,
        polygon,
        hash: md5(JSON.stringify(polygon)),
        bounds: geometric.polygonBounds(polygon),
      };
    }
  );

  return [...tenantPolygons, ...movableObstaclePolygons];
};

export function arraysEqual<T>(array1: T[], array2: T[]): boolean {
  if (array1.length !== array2.length) {
    return false;
  }

  for (let i = 0; i < array1.length; i++) {
    const item1 = array1[i];
    const item2 = array2[i];

    if (typeof item1 !== typeof item2) {
      return false;
    }

    if (Array.isArray(item1) && Array.isArray(item2)) {
      if (!arraysEqual(item1, item2)) {
        return false;
      }
    } else if (typeof item1 === "object" && typeof item2 === "object") {
      if (!objectsEqual(item1, item2)) {
        return false;
      }
    } else if (item1 !== item2) {
      return false;
    }
  }

  return true;
}

export function objectsEqual<T extends { [key: string]: any }>(
  object1: T,
  object2: T
): boolean {
  const keys1 = Object.keys(object1 ?? {});
  const keys2 = Object.keys(object2 ?? {});

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    if (typeof value1 !== typeof value2) {
      return false;
    }

    if (Array.isArray(value1) && Array.isArray(value2)) {
      if (!arraysEqual(value1, value2)) {
        return false;
      }
    } else if (typeof value1 === "object" && typeof value2 === "object") {
      if (!objectsEqual(value1, value2)) {
        return false;
      }
    } else if (value1 !== value2) {
      return false;
    }
  }

  return true;
}

export const anglediff = (a: number, b: number) => {
  return Math.abs(((a - b + 180) % 360) - 180);
};

export const removeNonNumericAndParse = (value: string): number | null => {
  // Remove all non-numeric characters and "." from the string
  const cleanedValue = value.replace(/[^0-9.]/g, "");

  // Attempt to parse the cleaned value to a number
  const parsedNumber = Number(cleanedValue);

  // Return the parsed number if it's not NaN, otherwise return null
  return isNaN(parsedNumber) ? null : parsedNumber;
};

export const copyImageAndUrlToClipboard = (
  canvas: HTMLCanvasElement,
  linkUrl: string,
  type: string = "hangar"
): void => {
  // Add the image data to the clipboard data object
  const html = `<div>
      <img src="${canvas.toDataURL()}" />
      <p>Edit ${type} here: <a href="${linkUrl}">${linkUrl}</a></p>
    </div>`;

  const leBlob = new Blob([html], { type: "text/html" });
  const item = new ClipboardItem({ "text/html": leBlob });
  navigator.clipboard.write([item]);
};

export const parseMultiPolygonZM = (wkt: string): MultiPolygon => {
  // Remove 'MULTIPOLYGON ZM' prefix and surrounding parentheses
  const cleanedWkt = wkt
    .replace("MULTIPOLYGON ZM ", "")
    .trim()
    .slice(1, -1);

  // Split into individual polygons
  const polygons = cleanedWkt.split(")),((");

  // Process each polygon
  const coordinates = polygons.map((polygon) => {
    // Remove outer parentheses if present
    const cleanedPolygon = polygon.replace(/\(|\)/g, "");
    // Split the polygon into rings
    const rings = cleanedPolygon.split("),(");

    // Process each ring
    return rings.map((ring) => {
      // Split the ring into points
      const points = ring.split(",");
      // Process each point
      return points.map((point) => {
        // Split the point into coordinates and convert to numbers
        const [x, y, z, m] = point
          .trim()
          .split(/\s+/)
          .map(Number);
        return [x, y, z, m];
      });
    });
  });

  // Construct GeoJSON MultiPolygon
  const geojson: MultiPolygon = {
    type: "MultiPolygon",
    coordinates,
  };

  return geojson;
};

/**
 * Forward fill the stacks with the tenants' positions from the previous stack. This is for when
 * the user updates an aircraft on a trip. We need to update subsequent stacks with the new
 * aircraft position.
 */
export const forwardFillStacks = (
  stack: Stack,
  start_dt: Date,
  stacks: Stack[]
) => {
  // find tenants that moved
  const previousStack = stacks.find((s) => s.snapshot_at === stack.snapshot_at);

  if (!previousStack) {
    return stacks;
  }

  const movedTenants = stack.tenants.filter((tenant) => {
    const previousTenant = previousStack.tenants.find(
      (t) => t.id === tenant.id
    );
    return (
      !previousTenant ||
      previousTenant.position.x !== tenant.position.x ||
      previousTenant.position.y !== tenant.position.y ||
      previousTenant.position.angle !== tenant.position.angle
    );
  });
  const movedTenantsIds = movedTenants.map((t) => t.id);

  const tenantTrips = Object.fromEntries(
    stack.tenants.map((tenant) => {
      return [
        tenant.id,
        tenant.trips?.find((t) => {
          const arrival = new Date(t.arrival);
          const departure = new Date(t.departure);
          return arrival <= start_dt && departure >= start_dt;
        }),
      ];
    })
  );

  return stacks
    .sort((a, b) => {
      const aDate = new Date(a.snapshot_at);
      const bDate = new Date(b.snapshot_at);
      return aDate.getTime() - bDate.getTime();
    })
    .map((s) => {
      // return the stack as is if it's the same as the stack we're forward filling
      if (s.id === stack.id) {
        return stack;
      }

      // no snapshot or not after the start date, return the stack as is
      if (!s.snapshot_at || new Date(s.snapshot_at) < start_dt) {
        return s;
      }

      // find the tenant in the stack and set the position to the tenant's position
      return {
        ...s,
        tenants: s.tenants.map((t) => {
          // if the tenant didn't moved, don't forward fill
          if (!movedTenantsIds.includes(t.id)) {
            return t;
          }

          const tenantInCurrentStack = stack.tenants.find(
            (tenant) => tenant.id === t.id
          );
          const tenantPositionInCurrentStack = tenantInCurrentStack?.position;

          if (!tenantPositionInCurrentStack) {
            return t;
          }
          const trip = tenantTrips[t.id];
          if (!trip) {
            return t;
          }

          // make sure the stack occurs during the tenant's trip
          const arrival = new Date(trip.arrival);
          const departure = new Date(trip.departure);
          if (
            new Date(s.snapshot_at) < arrival ||
            new Date(s.snapshot_at) > departure
          ) {
            return t;
          }

          return {
            ...t,
            position: {
              ...t.position,
              x: tenantPositionInCurrentStack.x,
              y: tenantPositionInCurrentStack.y,
              angle: tenantPositionInCurrentStack.angle,
            },
          };
        }),
      };
    });
};

export const forwardFillScheduleForTenant = (
  // postgrest: PostgrestClient,
  // location_id: string,
  tenant: Tenant,
  start_dt: Date,
  stacks: Stack[]
): Stack[] => {
  const scheduledStacks = stacks
    .filter((s) => s.snapshot_at)
    .sort((a, b) => {
      const aDate = new Date(a.snapshot_at);
      const bDate = new Date(b.snapshot_at);
      return aDate.getTime() - bDate.getTime();
    });
  const tenantCurrentTrip = tenant.trips?.find((t) => {
    const arrival = new Date(t.arrival);
    const departure = new Date(t.departure);
    return arrival <= start_dt && departure >= start_dt;
  });
  if (!tenantCurrentTrip) {
    console.error("Tenant does not have a trip at the given start date");
    return stacks;
  }
  // limit to only the tenant's trip that is associated with the start_dt
  scheduledStacks
    .filter((s) => {
      new Date(s.snapshot_at) > start_dt &&
        new Date(s.snapshot_at) < new Date(tenantCurrentTrip.departure);
    })
    .map((s) => {
      // find the tenant in the stack and set the position to the tenant's position
      const tenantInStack = s.tenants.find((t) => t.id === tenant.id);
      if (!tenantInStack) {
        return s;
      }
      tenantInStack.position = {
        ...tenantInStack.position,
        x: tenant.position.x,
        y: tenant.position.y,
        angle: tenant.position.angle,
      };
      // update the stack
      return {
        ...s,
        tenants: s.tenants.map((t) => (t.id === tenant.id ? tenantInStack : t)),
      };
    });

  // and we also need to update any stacks not currently in memory. we'll do this by calling
  // a stored procedure that will update the stacks in the database
  // await postgrest.rpc("forward_fill_schedule", {
  //   zentity_id: tenant.entity_id,
  //   location_id,
  //   start_dt: start_dt,
  //   end_dt: tenantCurrentTrip.departure,
  //   x: tenant.position.x,
  //   y: tenant.position.y,
  //   angle: tenant.position.angle,
  // });

  return stacks.map((s) => {
    const scheduledStack = scheduledStacks.find((ss) => ss.id === s.id);
    if (!scheduledStack) {
      return s;
    }
    return scheduledStack;
  });
};
