import { Stack } from "@mui/material";
import * as React from "react";
import { useTimeoutFn, useUpdateEffect } from "react-use";
import { useActiveFBOs } from "../../containers/ActiveFBOContainer";
import { AutocompleteResultsContainer } from "../../containers/AutocompleteResultsContainer";
import { useMultiHangarState } from "../../containers/MultiHangarContainer";
import { useTowingEquipmentState } from "../../containers/TowingEquipmentContainer";
import { useThisHangar } from "../../hooks/useThisHangar";
import { forwardFillStacks, getStackPolygons } from "../../hooks/utils";
import { ParamSet, Preference, Stack as StackType } from "../../types";
import { convertAircraftPreferencesToAlgorithmPreferences } from "../../utils";
import { Layout } from "../../widgets/Layout";
import { LayoutToolbar } from "../../widgets/LayoutToolbar";
import { ZoomControl } from "../../widgets/ZoomControl";
import { HOLDING_AREA_ID } from "../MultiHangar/HoldingArea";
import { StackStrategy } from "./GenerateParamSets";
import { HangarStack } from "./Hangar";
import { formatObstacles } from "./HangarLayout/utils";
import { HangarLeftPanel } from "./HangarLeftPanel";
import { HangarPrintLayout } from "./HangarPrintLayout";
import { useHangarAutocomplete } from "./useHangarAutocomplete";

import { max, min } from "lodash";
import { useNavigate } from "react-router-dom";
import { useApi } from "../../containers/ApiContainer";
import {
  CollapsibleRampActionCard,
  useLayout,
} from "../Ramp/CollapsibleRampActionCard";
import {
  CustomPlacementOptions,
  defaultCustomPlacementOptions,
} from "./CustomStackDialog";

import { create } from "zustand";

interface DateRange {
  activeDate: Date;
  setActiveDate: (date: Date) => void;
  minDate: Date;
  setMinDate: (date: Date) => void;
  maxDate: Date;
  setMaxDate: (date: Date) => void;
}

export const useDateRange = create<DateRange>()((set) => ({
  activeDate: null,
  setActiveDate: (activeDate: Date) => set({ activeDate }),
  minDate: null,
  setMinDate: (minDate: Date) => set({ minDate }),
  maxDate: null,
  setMaxDate: (maxDate: Date) => set({ maxDate }),
}));

export const HANGAR_BACKGROUND_COLORS = {
  reference: "#F4D2CD",
  testing: "#23967F",
};

type Props = {
  id: string;
  stacks: HangarStack[];
  setStacks: (stacks: HangarStack[]) => void;
  isReference?: boolean;
  isAlgorithmTesting?: boolean;
  schedule?: boolean;
};

export const HangarPresenter: React.FC<Props> = ({
  id,
  stacks,
  setStacks,
  isReference,
  schedule = false,
  isAlgorithmTesting = false,
}) => {
  const navigate = useNavigate();
  const { postgrest } = useApi();
  const { isDrawerOpen } = useLayout();
  const { isMultiHangar } = useMultiHangarState();
  const [stackStrategy, setStackStrategy] = React.useState<StackStrategy>(
    StackStrategy.normal
  );
  const { activeFBO } = useActiveFBOs();
  const { towingEquipments: allTowingEquipments } = useTowingEquipmentState();
  const defaultTowingEquipmentId = allTowingEquipments.find(
    (t) => t.in_use && t.is_default
  )?.towing_equipment_id;
  const { hangar, setHangar, history } = useThisHangar();
  const [width, setWidth] = React.useState<number>(700);
  const [customPlacementOptions, setCustomPlacementOptions] = React.useState<
    CustomPlacementOptions
  >(defaultCustomPlacementOptions(activeFBO, hangar));
  const {
    activeDate,
    setActiveDate,
    minDate,
    maxDate,
    setMinDate,
    setMaxDate,
  } = useDateRange();

  // min/max dates if we're in schedule mode
  React.useEffect(() => {
    if (!schedule) {
      return;
    }
    const snapshots = hangar.stacks
      .filter((s) => Boolean(s.snapshot_at))
      .map((s) => +new Date(s.snapshot_at))
      .sort();
    // if no snapshots, return today
    if (!snapshots.length) {
      setMinDate(new Date());
      setMaxDate(new Date());
      return;
    }
    setActiveDate(new Date(snapshots[Math.floor(snapshots.length / 2)]));
    setMinDate(new Date(min(snapshots)));
    setMaxDate(new Date(max(snapshots)));
  }, [location]);

  React.useEffect(() => {
    setCustomPlacementOptions(defaultCustomPlacementOptions(activeFBO, hangar));
  }, [stackStrategy]);

  const div = React.useCallback(
    (node) => {
      if (node?.offsetWidth && node?.offsetHeight) {
        let maxUnplacedAircraftLength = hangar.stack.tenants
          .filter((t) => t.position.x === null)
          .map((t) => t.aircraft.length + 20)
          .reduce((a, b) => Math.max(a, b), 0);

        maxUnplacedAircraftLength = Math.max(maxUnplacedAircraftLength, 70);

        const feetToPixels =
          node?.offsetHeight / (hangar.depth + maxUnplacedAircraftLength);
        const height =
          node.offsetHeight - maxUnplacedAircraftLength * feetToPixels;
        setWidth((hangar.width * height) / hangar.depth);
      }
    },
    [isDrawerOpen]
  );

  const polygons = React.useMemo(
    () =>
      getStackPolygons(
        hangar.stack,
        width,
        1,
        isMultiHangar,
        id === HOLDING_AREA_ID
      ),
    [width, hangar?.stack?.tenants, hangar?.stack?.movableObstacles]
  );

  const setStack = React.useCallback(
    async (stack: StackType) => {
      if (schedule) {
        // we want to propogate the new positions into the "future". we will "forward fill" in
        // time so that the positions in the active stack will be the same for the remaining time
        // periods in the schedule
        const forwardFilledStacks = forwardFillStacks(
          stack,
          new Date(stack.snapshot_at),
          hangar.stacks
        );
        // for debugging
        const nextSnapshotDt = new Date(stack.snapshot_at);
        nextSnapshotDt.setMinutes(nextSnapshotDt.getMinutes() + 15);
        const nextStack = forwardFilledStacks.find(
          (s) => +new Date(s.snapshot_at) === +nextSnapshotDt
        );
        if (!nextStack) {
          return;
        }
        // console.log("ffStacks", ffStacks);
        // console.log(nextStack.snapshot_at, nextStack.tenants[0].position);
        // console.log(
        //   stack.tenants[0].selected,
        //   stack.tenants[0].position.x === nextStack.tenants[0].position.x,
        //   stack.tenants[0].position.y === nextStack.tenants[0].position.y,
        //   stack.tenants[0].position.angle ===
        //     nextStack.tenants[0].position.angle
        // );

        // we are setting the active stack within the forward filled stacks, so
        // we will just update the stacks property
        setHangar({
          ...hangar,
          stacks: forwardFilledStacks,
          stack,
        });
        return;
      }

      setHangar({
        ...hangar,
        stack: {
          ...hangar.stack,
          ...stack,
        },
      });
    },
    [hangar, setHangar]
  );

  // stack being edited
  React.useEffect(() => {
    console.log("setting to active stack", schedule, activeDate);
    if (schedule) {
      const activeStack = hangar.stacks
        .sort((a, b) => +new Date(a.snapshot_at) - +new Date(b.snapshot_at))
        .find((s) => +new Date(s.snapshot_at) === +activeDate);

      if (!activeStack) {
        console.log(`no active stack for ${activeDate}`);
        return;
      }

      console.log("setting active stack", activeStack?.name);
      setHangar({
        ...hangar,
        stack: activeStack,
      });
    }
  }, [activeDate]);

  // bug hack for first load. it won't set the active stack on the first render properly
  useTimeoutFn(() => {
    if (schedule) {
      const activeStack = hangar.stacks
        .sort((a, b) => +new Date(a.snapshot_at) - +new Date(b.snapshot_at))
        .find((s) => +new Date(s.snapshot_at) === +activeDate);

      if (!activeStack) {
        console.log(`no active stack for ${activeDate}`);
        return;
      }

      setHangar({
        ...hangar,
        stack: activeStack,
      });
    }
  }, 100);

  console.log("\thangar.stack.name: ", hangar.stack.name);

  const paramSet: ParamSet = React.useMemo(() => {
    if (!hangar) {
      return null;
    }
    return {
      run_id: null,
      label: "base case",
      // slim down the hangar for the API call
      hangar: {
        id: hangar.id,
        width: hangar.width,
        depth: hangar.depth,
        left_door: hangar.left_door,
        right_door: hangar.right_door,
        garage_doors: hangar.garageDoors ?? [],
      },
      // slim down the tenant so it's just what we need to do the placement
      aircrafts_to_place: hangar.stack.tenants
        .filter(
          (t) => t.position.preferences?.indexOf(Preference.LOCKED) === -1
        )
        .map((tenant) => ({
          id: tenant.aircraft.id,
          placement_id: tenant.placement_id,
          angles_to_try: [],
          sectors_to_try: null,
          preferences: convertAircraftPreferencesToAlgorithmPreferences(
            tenant.position.preferences
          ),
        })),
      locked_aircrafts: hangar.stack.tenants
        .filter((t) => t.position.preferences?.indexOf(Preference.LOCKED) > -1)
        .map((tenant) => ({
          id: tenant.aircraft.id,
          placement_id: tenant.placement_id,
          x: tenant.position.x,
          y: tenant.position.y,
          angle: tenant.position.angle,
        })),
      obstacles: formatObstacles(hangar),
      options: {
        ...hangar.stack.options,
        ...customPlacementOptions,
      },
    };
  }, [hangar]);

  // TODO: work with activeStack
  const autocomplete = useHangarAutocomplete(
    {
      hangar,
      defaultTowingEquipmentId,
      width,
      customPlacementOptions,
    },
    [hangar, polygons, customPlacementOptions, defaultTowingEquipmentId]
  );

  const positions = hangar?.stack?.tenants
    .map((t) => `${t.position.x},${t.position.y},${t.position.angle}`)
    .join("");

  useUpdateEffect(() => {
    setHangar({
      ...hangar,
      last_stacked: new Date().toISOString(),
    });
  }, [positions]);

  if (!hangar) {
    return null;
  }

  return (
    <AutocompleteResultsContainer>
      <Stack
        direction="row"
        sx={{
          width: "100%",
          height: "100%",
        }}
      >
        <CollapsibleRampActionCard
          location={{ type: "hangar", ...hangar }}
          onClickBack={() => navigate("/hangars")}
        >
          <HangarLeftPanel
            width={isDrawerOpen ? 400 : 125}
            hangar={hangar}
            setHangar={setHangar}
            stacks={stacks}
            setStacks={setStacks}
            stackStrategy={stackStrategy}
            setStackStrategy={setStackStrategy}
            paramSet={paramSet}
            customPlacementOptions={customPlacementOptions}
            setCustomPlacementOptions={setCustomPlacementOptions}
            isReference={isReference}
            schedule={schedule}
            isAlgorithmTesting={isAlgorithmTesting}
            readOnly={
              activeFBO?.user_role === "manager"
                ? activeFBO?.settings?.viewOnlyForManager
                : activeFBO?.settings?.viewOnlyForOperator
            }
          />
        </CollapsibleRampActionCard>

        <Stack
          direction="column"
          sx={{
            flex: 1,
            height: "100%",
            width: "100%",
          }}
        >
          <LayoutToolbar
            schedule={schedule}
            location={{ type: "hangar", ...hangar }}
            setLocation={({ type, ...location }) => {
              setHangar({ ...hangar, ...location });
            }}
            stack={hangar.stack}
            setStack={setStack}
            setStacks={setStacks}
            onClickUndo={() => {
              history && history.undo();
            }}
            renderOnPrint={() => (
              <HangarPrintLayout hangar={hangar} stack={hangar.stack} />
            )}
          />
          <div
            ref={div}
            style={{
              flex: 1,
              // This stops the aircraft being dragged outside of this section
              // overflow: "scroll",
              position: "relative",
              height: "100%",
              margin: "20px",
            }}
          >
            <Layout
              width={width}
              location={{ type: "hangar", ...hangar }}
              stack={hangar.stack}
              setStack={setStack}
              autocomplete={autocomplete}
              activeDate={activeDate}
              setActiveDate={setActiveDate}
              minDate={minDate}
              maxDate={maxDate}
              options={{
                hideUnplaced: false,
                readOnly: false,
                frozen:
                  activeFBO?.user_role === "manager"
                    ? activeFBO?.settings?.viewOnlyForManager
                    : activeFBO?.settings?.viewOnlyForOperator,
                printable: false,
                selectable: false,
                disableGrabScroll: isMultiHangar,
              }}
            />
          </div>
        </Stack>

        <ZoomControl
          currentZoom={width}
          increment={0.1}
          onClickPlus={() => setWidth(width * 1.1)}
          onClickMinus={() => setWidth(width * 0.9)}
        />
      </Stack>
    </AutocompleteResultsContainer>
  );
};
