import { uniqBy } from "lodash";
import * as React from "react";
import { useActiveFBOs } from "../containers/ActiveFBOContainer";
import { useApi } from "../containers/ApiContainer";
import { HOLDING_AREA_ID } from "../screens/MultiHangar/HoldingArea";
import {
  GarageDoor,
  Hangar,
  MovableObstacle,
  Obstacle,
  Position,
  Stack,
  Tenant,
  Trip,
} from "../types";
import { fetchHangar } from "./api";
import { isLocationChangeMeaningful } from "./useHangar";
import { useStateHistory } from "./useMeaningfulStateHistory";
import useSyncToTable from "./useSyncToTable";

const hasMeaningfulChanges = (prev: Hangar[], next: Hangar[]) => {
  if (!prev || !next) {
    return true;
  }
  return prev.some((p, i) => isLocationChangeMeaningful(p, next[i]));
};

export const useMultipleHangars = (
  hangar_ids: string[],
  isReference: boolean
) => {
  const { postgrest } = useApi();
  const { activeFBO } = useActiveFBOs();
  const [ready, setReady] = React.useState<boolean>(false);
  const [hangars, setHangars, undo] = useStateHistory<Hangar[]>(
    null,
    hasMeaningfulChanges
  );
  const setHangar = React.useCallback(
    (hangar: Hangar) => {
      // update the hangar that's currently being modified
      // if the hangar moved a tenant into it, then remove that
      // tenant from all other hangars
      const hangarsTenantIds = hangar.stack.tenants.map((t) => t.id);
      const newHangars = hangars.map((h) => {
        if (h.id === hangar.id) {
          return hangar;
        }

        // remove any duplicate tenants. this should not happen but has apparently happened
        // at Maven FBO in the past
        const tenants = h.stack.tenants
          .filter((t) => !hangarsTenantIds.includes(t.id))
          .filter((t, i, self) => self.findIndex((s) => s.id === t.id) === i);

        return {
          ...h,
          stack: {
            ...h.stack,
            tenants,
          },
        };
      });
      setHangars(newHangars);
    },
    [hangars, setHangars]
  );

  const realHangars = hangars?.filter((h) => h.id !== HOLDING_AREA_ID);
  const holdingArea = hangars?.find((h) => h.id === HOLDING_AREA_ID);

  // watches for "core" diffs on the hangar. width, depth, name, etc.
  useSyncToTable<Hangar[]>({
    thingToWatch: realHangars,
    tableName: "hangar",
  });

  // watch for stack
  const stacks: Stack[] = [];
  for (const hangar of realHangars ?? []) {
    for (const stack of hangar.stacks ?? []) {
      if (stack.id === hangar.stack.id) {
        stacks.push(hangar.stack);
      } else {
        stacks.push(stack);
      }
    }
  }

  useSyncToTable<Stack[]>({
    tableName: "stack",
    thingToWatch: Boolean(realHangars) ? stacks : null,
  });

  // core info for a tenant can be modified within the holding area. so we need to include these
  // in the sync
  const tenants = uniqBy(
    [
      ...stacks?.flatMap((stack) => stack.tenants),
      ...(holdingArea?.stack.tenants || []),
    ],
    "id"
  );
  useSyncToTable<Tenant[]>({
    // pass in null until we actually have data
    thingToWatch: Boolean(realHangars) ? tenants : null,
    tableName: "tenant",
    deleteFromDatabase: async (id: string) => {
      // we don't actually delete tenants. they just leave the hangar. we're still
      // tracking them in this file b/c we need to be able to edit their info from
      // the hangar.
    },
  });

  const movableObstacles =
    [...stacks?.map((s) => [...s.movableObstacles])].flat() ?? [];
  useSyncToTable<MovableObstacle[]>({
    thingToWatch: Boolean(realHangars) ? movableObstacles : null,
    tableName: "movable_obstacle",
    deleteFromDatabase: async (id: string) => {
      // we don't actually delete movable obstacles. they just leave the hangar. we're still
      // tracking them in this file b/c we need to be able to edit their info from
      // the hangar.
    },
  });

  const currentPositions: Position[] = [];
  for (const stack of stacks ?? []) {
    for (const tenant of stack.tenants ?? []) {
      currentPositions.push(tenant.position);
    }
    for (const movableObstacle of stack.movableObstacles ?? []) {
      currentPositions.push(movableObstacle.position);
    }
  }

  useSyncToTable<Position[]>({
    // pass in null until we actually have data
    thingToWatch: Boolean(realHangars) ? currentPositions : null,
    tableName: "position",
    // wow this is ugly. if i put this in the hook, it will cause a circular dependency
    // so i'm just going to put it here. life soldiers on.
    onUpdate: async (rows) => {
      const hangarsAffected = realHangars.filter((h) =>
        rows.some((r) => h.stack.tenants.some((t) => t.position.id === r.id))
      );
      await postgrest
        .from("hangar")
        .update({
          last_stacked: new Date().toISOString(),
        })
        .in(
          "id",
          hangarsAffected.map((h) => h.id)
        );
    },
    // debounce: 500,
  });

  const trips: Trip[] = [];
  for (const stack of stacks ?? []) {
    for (const tenant of stack.tenants ?? []) {
      for (const trip of tenant.trips ?? []) {
        trips.push(trip);
      }
    }
  }

  useSyncToTable<Trip[]>({
    thingToWatch: Boolean(realHangars) ? uniqBy(trips, "id") : null,
    tableName: "trip",
  });

  const obstacles = Boolean(realHangars)
    ? [...realHangars?.map((h) => [...(h.obstacles ?? [])])].flat()
    : [];

  useSyncToTable<Obstacle[]>({
    thingToWatch: Boolean(realHangars) ? obstacles : null,
    tableName: "obstacle",
  });

  const garageDoors = Boolean(realHangars)
    ? [...realHangars?.map((h) => [...(h.garageDoors ?? [])])].flat()
    : [];
  useSyncToTable<GarageDoor[]>({
    thingToWatch: Boolean(realHangars) ? garageDoors : null,
    tableName: "garage_door",
  });

  // ok let's grab the hangars we only do this once. changes made in memory will be
  // synced to the database via the useSyncToTable hook
  React.useEffect(() => {
    (async () => {
      const doit = hangar_ids.map(
        async (hangar_id) =>
          await fetchHangar(postgrest, activeFBO, hangar_id, isReference)
      );
      const zHangars = await Promise.all(doit);
      zHangars.push({
        id: HOLDING_AREA_ID,
        name: "Holding Area",
        width: 100,
        depth: 300,
        height: 50,
        stack: {
          id: "holding-area-stack",
          name: "current",
          isReference: false,
          tenants: [],
          movableObstacles: [],
          options: {
            //
          },
        },
        stacks: [
          {
            id: "holding-area-stack",
            name: "current",
            isReference: false,
            tenants: [],
            movableObstacles: [],
            options: {
              //
            },
          },
        ],
        obstacles: [],
        garageDoors: [],
        created_at: new Date().toISOString(),
        last_stacked: new Date().toISOString(),
      });
      setHangars(zHangars);
      setReady(true);
    })();
  }, []);

  return {
    ready:
      ready &&
      Boolean(hangars) &&
      Boolean(hangars.length === hangar_ids.length + 1),
    getHangar: (hangar_id: string) => hangars?.find((h) => h.id === hangar_id),
    hangars: hangars ?? [],
    setHangar,
    setHangars,
    // setHangars: (newHangars: Hangar[]) => {
    //   // make sure there are no duplicate tenants
    //   setHangars(
    //     newHangars.map((h) => ({
    //       ...h,
    //       stack: {
    //         ...h.stack,
    //         tenants: h.stack.tenants.filter(
    //           (t, i, self) => self.findIndex((s) => s.id === t.id) === i
    //         ),
    //       },
    //     }))
    //   );
    // },
    history: {
      undo,
    },
    isReference,
  };
};
